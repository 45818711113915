/** @format */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getArticles, addArticle } from "./api";

export const addArticles = createAsyncThunk(
  "articles/addArticles",
  async (payload) => {
    try {
      const response = await addArticle(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getArticle = createAsyncThunk(
  "articles/getArticle",
  async (payload) => {
    try {
      const response = await getArticles();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
