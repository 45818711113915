/** @format */

import { Box, Typography } from "@mui/material";
import Investor from "../investors";

import { Link } from "react-router-dom";
import Creators from "../creators";

const LeaderBoard = ({ topCreators, topInvestors }) => {
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { md: "1fr 1fr", xs: "1fr" },
          width: "100%",
          gap: 10,
        }}>
        <Box
          sx={{ cursor: "pointer", textDecoration: "none" }}
          component={Link}
          to="/investor">
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              paddingBottom: 1,
              color: "#000 !important",
            }}>
            Investors
          </Typography>
          {topInvestors &&
            topInvestors.length > 0 &&
            topInvestors.map(({ username, img, rating, total_amount }) => (
              <Investor
                username={username}
                img={img}
                ratings={rating}
                total_amount={total_amount}
              />
            ))}
        </Box>
        <Box sx={{ cursor: "pointer", textDecoration: "none" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              paddingBottom: 1,
              color: "#000 !important",
            }}>
            Creators
          </Typography>
          {topCreators &&
            topCreators.length > 0 &&
            topCreators?.map(({ username, img, rating, total_amount }) => (
              <Creators
                username={username}
                img={img}
                ratings={rating}
                total_amount={total_amount}
              />
            ))}
        </Box>
      </Box>
    </>
  );
};

export default LeaderBoard;
