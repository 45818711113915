/** @format */

import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CreatedCards from "../createdCards";
import { useSelector } from "react-redux";
import "./style.css";
import { CarouselArrow } from "../../utiles/units";
import Carousel from "react-elastic-carousel";
import { Divider } from "@mui/material";
import Wrapper from "../wrapper";

export default function CreatorTabs() {
  const { inWallet, isInwalletLoadingSuccess } = useSelector(
    (state) => state.inWalletSlicer
  );
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  if (isInwalletLoadingSuccess) {
    return (
      <Box>
        <Wrapper>
          <Box>
            <Typography variant="h1">Created Energy Assets</Typography>
          </Box>

          <Divider sx={{ py: 1 }} />
        </Wrapper>
        <Box py={3} px={1}>
          {inWallet.data.createdArts.length > 0 ? (
            <Carousel
              autoPlaySpeed={5000}
              enableAutoPlay
              disableArrowsOnEnd={false}
              breakPoints={breakPoints}
              infinite={true}
              pagination={false}
              style={{ justifyContent: "space-evenly !important" }}
              renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}>
              {inWallet.data.createdArts.map((item) => {
                return <CreatedCards {...item} />;
              })}
            </Carousel>
          ) : (
            <Box>
              <Typography sx={{ textAlign: "center" }} variant="h3">
                No Assets Found
              </Typography>
            </Box>
          )}
        </Box>
        <Box py={3} px={1}></Box>
      </Box>
    );
  }
}
