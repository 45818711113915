/** @format */

import { lumiShare } from "../../http/config";

export const addToWishListApi = async (payload) => {
  try {
    const res = await lumiShare.post("app.v1/api/addToWishList", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getWishListApi = async (payload) => {
  try {
    const res = await lumiShare.get(`app.v1/api/getWishList/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const searchParamsApi = async (payload) => {
  try {
    const res = await lumiShare.get(`app.v1/api/search?name=${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};

////////////////////////////////////////////////////////////////
export const deleteWishListApi = async (payload) => {
  try {
    const res = await lumiShare.post("app.v1/api/deleteFromWishList", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
/////////////////////////////////////////////////////////////////
export const reactApi = async ({ value, walletAddress, tokenId, amountId }) => {
  let result = {
    walletAddress,
    tokenId,
    amountId,
  };
  try {
    const res = await lumiShare.post(
      `/app.v1/api/react?react=${value}`,
      result
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const usernameApi = async (payload) => {
  try {
    const res = await lumiShare.get(`app.v1/api/checkUsername/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};
