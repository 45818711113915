/** @format */

import { ThemeProvider } from "@emotion/react";
import theme from "./theme";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import ReduxProvider from "./store/provider";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { PolygonAmoyTestnet, BinanceTestnet } from "@thirdweb-dev/chains";
import WalletConnect from "./hooks/thirdwebHooks/WalletConnect";

function App() {
  const options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_RIGHT,
    timeout: 5000,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider>
        <ThirdwebProvider
          supportedChains={[BinanceTestnet, PolygonAmoyTestnet]}
          clientId={"a2b779af9c0c2715c4b24a765d2968c4"}
          activeChain={BinanceTestnet}>
          <WalletConnect>
            <AlertProvider template={AlertTemplate} {...options}>
              <RouterProvider router={router} />
            </AlertProvider>
          </WalletConnect>
        </ThirdwebProvider>
      </ReduxProvider>
    </ThemeProvider>
  );
}

export default App;
