/** @format */

import { useTheme, useMediaQuery } from "@mui/material";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from "recharts";
import data from "./data";
const Chart = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <ResponsiveContainer width={isSmallScreen ? "100%" : "80%"} aspect={3}>
        <BarChart data={data} width={600} height={600}>
          <XAxis dataKey="name" />
          <YAxis />
          <Bar
            dataKey="pv1"
            stackId="a"
            fill="#FEDB33"
            barSize={isMediumScreen ? 10 : 40}
          />
          <Bar
            dataKey="pv2"
            stackId="a"
            fill="#FDC10E"
            barSize={isMediumScreen ? 10 : 40}
          />
          <Bar
            dataKey="pv3"
            stackId="a"
            fill="#E99511"
            barSize={isMediumScreen ? 10 : 40}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
export default Chart;
