/** @format */

// ConnectWalletComponent.js
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Input from "../input";
import Button from "../button";

const ConnectWalletComponent = ({ formik }) => {
  const [walletAddress, setWalletAddress] = useState("");
  const dispatch = useDispatch();

  const connectAndSaveWalletAddress = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const address = accounts[0];

      setWalletAddress(address);
      formik.setFieldValue("wallet", address);
      dispatch({ type: "SET_WALLET_ADDRESS", payload: address });
    } catch (error) {
      console.error("Error connecting to wallet:", error.message);
    }
  };

  return (
    <div>
      <Box py={0.5} sx={{ width: "100%" }}>
        <label style={{ color: "#8f979e", fontSize: "14px", paddingLeft: 10 }}>
          Wallet Address
        </label>
        <Box py={0.5} sx={{ width: "100%" }}>
          <Input
            name="verifyEmail"
            type="verifyEmail"
            className="form-control pe-5"
            value={walletAddress || ""}
            sx={{ width: "100%" }}
          />
        </Box>
      </Box>

      <Button
        sx={{
          backgroundColor: "#34C77B !important",
          textTransform: "capitalize",
          color: "#fff !important",
        }}
        onClick={connectAndSaveWalletAddress}>
        Get Wallet Address?
      </Button>
    </div>
  );
};

export default ConnectWalletComponent;
