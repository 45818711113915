/** @format */

import { Box, Typography } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import SearchInput from "../../components/searchInput";
import Filter from "../../components/filterButton";
import LeaderboardTab from "../../components/leaderboardTab";
import { useEffect, useState } from "react";
import { leaderBoardData } from "../../redux/singleCreator/singleCreator.action";
import { useDispatch } from "react-redux";

const LeaderBoard = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const handleValue = (e) => {
    const inputValue = e.target.value;
    setName(inputValue.toLowerCase());
  };
  useEffect(() => {
    dispatch(leaderBoardData());
  }, [dispatch]);
  return (
    <Box py={5}>
      <Typography sx={{ fontSize: "40px", fontWeight: "bold" }}>
        LeaderBoard
      </Typography>

      <LeaderboardTab name={name} setName={setName} />
    </Box>
  );
};

export default LeaderBoard;
