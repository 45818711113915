/** @format */

import { Button as MuiButton } from "@mui/material";
import { withStyles } from "@mui/styles";

const MainButton = withStyles((theme) => ({
  root: {
    color: "black",
    width: "100%",
  },

  contained: {
    fontSize: "10px !important",
    textTransform: "capitalize !important",
    height: "35px !important",
    background: "#34C77B ",
    color: "green !important",
    width: "140px",
  },
  outlined: {
    fontSize: "10px !important",
    textTransform: "capitalize !important",
    height: "35px !important",
    color: "#4ACD89 !important",
    border: "1.5px solid #4ACD89 !important",
    width: "140px",
  },
  standard: {
    fontSize: "10px !important",
    height: "30px !important",
    background: "white",
    textTransform: "capitalize !important",
    width: "140px",
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
}))(MuiButton);

export default MainButton;
