/** @format */

import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { collections as CollectionsAction } from "../../redux/inWallet/inwallet.action";
import CollectedCards from "../../components/collectedCards";
import Loading from "../../components/loading";
import Notifications from "../../components/Notifications";

const ArtPieces = () => {
  const { tokenId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CollectionsAction(tokenId));
  }, [dispatch, tokenId]);
  const { collections, isCollectionsLoading } = useSelector(
    (state) => state.inWalletSlicer
  );
  return (
    <Box>
      <Notifications />
      <Box p={5}>
        <Typography variant="h1" sx={{ textAlign: "center" }}>
          Collections
        </Typography>
      </Box>
      <Divider />

      {isCollectionsLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              lg: "1fr 1fr 1fr 1fr ",
              md: "1fr 1fr 1fr",
              sm: "1fr 1fr",
              xs: "1fr",
            },
            padding: {
              lg: "30px 0",
              md: "20px 0px",
              sm: "20px 0px",
              xs: "20px 0px",
            },
            gap: "20px",
            justifyItems: {
              lg: "start",
              md: "center",
              sm: "center",
              xs: "center",
            },
          }}>
          {collections &&
            collections?.collections?.map((item) => {
              return <CollectedCards {...item} />;
            })}
        </Box>
      )}
    </Box>
  );
};
export default ArtPieces;
