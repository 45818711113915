/** @format */

import { Box, Typography } from "@mui/material";
import CustomAvatar from "../avatar";
import "./style.css";
import StoryUpload from "../storyUploadModal";
import { PROFILE_BASE_URL } from "../../http/config";
import { useSelector } from "react-redux";

const Stories = ({ handleStoryClick, handleOpen }) => {
  const handleAvatarClick = (
    creatorId,
    cratorUsername,
    creatorImg,
    storyContent
  ) => {
    handleStoryClick(creatorId, cratorUsername, creatorImg, storyContent);
  };
  const { stories, isStoriesLoadingSuccess } = useSelector(
    (state) => state.storiesSlice
  );
  const { type } = useSelector((state) => state.auth.user);
  console.log(type);
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        margin: "2rem 0",
        flexDirection: "column",
      }}>
      <Box>
        <Typography variant="h1" component="h2">
          Stories
        </Typography>
      </Box>

      <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
        {type === "creator" && (
          <Box>
            <StoryUpload />
          </Box>
        )}

        <Box sx={{ display: "flex", gap: 1 }}>
          {isStoriesLoadingSuccess &&
            stories?.map(
              ({
                creatorId,
                cratorUsername,
                creatorImg,
                stories: storyContent,
              }) => (
                <Box
                  key={creatorId}
                  onClick={() =>
                    handleAvatarClick(
                      creatorId,
                      cratorUsername,
                      creatorImg,
                      storyContent
                    )
                  }>
                  <CustomAvatar
                    src={PROFILE_BASE_URL + creatorImg}
                    width="55px"
                    height="55px"
                    handleOpen={handleOpen}
                  />
                </Box>
              )
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default Stories;
