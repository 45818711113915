/** @format */

import { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import CreatorProfile from "../../components/createrProfile";
import CreatorTabs from "../../components/creatorTabs";
import { useDispatch, useSelector } from "react-redux";
import { inWallet } from "../../redux/inWallet/inwallet.action";
import { useParams } from "react-router-dom";
import { singleCreator } from "../../redux/singleCreator/singleCreator.action";
import { resetStateMint } from "../../redux/mint/mint.slicer";
import Loading from "../../components/loading";
import { clearLogs } from "../../redux/arts/logs.slicer";
import Notifications from "../../components/Notifications";
import Articalslist from "../../components/articalsList";
import Videoslist from "../../components/videosList";
import { data } from "../../components/Articles/data";
import { Data } from "@react-google-maps/api";
import Wrapper from "../../components/wrapper";

const CreatorPage = () => {
  const { isPutOnSaleFixedSuccess, isPutOnSaleFixedFailed } = useSelector(
    (state) => state.mintSlicer
  );
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const walletData = useSelector((state) => state.inWalletSlicer.inWallet);
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let wallet = null;
        if (isAuthenticated) {
          wallet = user.walletAddress;
        }
        let payload = { wallet: wallet, user: id };
        await Promise.all([dispatch(singleCreator(payload))]);
        dispatch(inWallet(user?.walletAddress));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, user, id, isAuthenticated]);
  if (loading) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Loading />
      </p>
    );
  }
  setTimeout(() => {
    if (isPutOnSaleFixedSuccess) {
      dispatch(resetStateMint());
      dispatch(clearLogs());
      // window.location.reload();
    }

    if (isPutOnSaleFixedFailed) {
      dispatch(resetStateMint());
      dispatch(clearLogs());
    }
  }, 2000);
  return (
    <Box sx={{ marginBottom: { md: 10, xs: 5 } }}>
      <Notifications />

      <CreatorProfile inwalletData={walletData} />

      <CreatorTabs />
      <Wrapper>
        <Box>
          <Typography variant="h1">Videos</Typography>
        </Box>
        <Divider sx={{ py: 1 }} />
        <Box sx={{ display: "flex", paddingY: "10px", flexWrap: "wrap" }}>
          <Videoslist data={walletData?.data?.videos} />
        </Box>

        <Box>
          <Typography variant="h1">Articles</Typography>
        </Box>
        <Divider sx={{ py: 1 }} />
        <Box sx={{ display: "flex", paddingY: "10px", flexWrap: "wrap" }}>
          <Articalslist data={walletData?.data?.articles} />
        </Box>
      </Wrapper>
    </Box>
  );
};

export default CreatorPage;
