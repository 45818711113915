/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { putOnFixedPriceApi, mintingApi } from "./api";

async function postData({
  tokenId,
  transactionHash,
  image,
  creatorWallet,
  nftContract,
  title,
  description,
  amount,
  apy,
  metadata,
  creatorWallet: wallet,
  country,
  projectSize,
  yearsInOperation,
  capitalInvested,
  mezzanineFinancing,
  useOfProceeds,
  yieldOnMezzanineDebt,
}) {
  const fd = new FormData();
  fd.append("tokenId", tokenId);
  fd.append("title", title);
  fd.append("description", description);
  fd.append("image", image);
  fd.append("creatorWallet", creatorWallet);
  fd.append("metadata", metadata);
  fd.append("transactionhash", transactionHash);
  fd.append("amount", amount);
  fd.append("apy", apy);
  fd.append("country", country);
  fd.append("projectSize", projectSize);
  fd.append("yearsInOperation", yearsInOperation);
  fd.append("capitalInvested", capitalInvested);
  fd.append("mezzanineFinancing", mezzanineFinancing);
  fd.append("useOfProceeds", useOfProceeds);
  fd.append("yieldOnMezzanineDebt", yieldOnMezzanineDebt);
  try {
    const response = await mintingApi(fd);
    return response;
  } catch (err) {
    return err;
  }
}
// function parseCompare(firstAddress, secondAddress) {
//   const slicedAddress = firstAddress.slice(2, firstAddress.length);
//   const lowercaseAddress = slicedAddress.toLowerCase();
//   const compareAddress = `"0x" "000000000000000000000000" ${lowercaseAddress}`;
//   return compareAddress === secondAddress ? true : false;
// }

export const minted = createAsyncThunk(
  "minting/mint",
  async (payload, { getState, dispatch }) => {
    try {
      const response = await postData(payload);
      return response;
    } catch (err) {
      throw new Error(err);
    }
  }
);
async function postDataFixedPrice({
  orderId,
  tokenId,
  transactionHash,
  ownerWallet,
  price,
  amountId,
}) {
  const fd = new FormData();
  fd.append("orderId", orderId);
  fd.append("tokenId", tokenId);
  fd.append("transactionHash", transactionHash);
  fd.append("ownerWallet", ownerWallet);
  fd.append("price", price);
  fd.append("amountId", amountId);

  console.log("Form data: ", fd);

  try {
    const response = await putOnFixedPriceApi(fd);
    return response;
  } catch (err) {
    console.log("Error while posting data: ", err);
    return err;
  }
}

export const putOnFixedPrice = createAsyncThunk(
  "minting/putOnFixedPrice",
  async (payload, { getState, dispatch }) => {
    try {
      const response = await postDataFixedPrice(payload);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
);
