import React, { useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { FaArrowDownWideShort, FaArrowUpWideShort } from "react-icons/fa6"; // Assuming this import path is correct
import VerticalProgress from "../verticalProgress";

const BigMovers = () => {
  const [sortOrder, setSortOrder] = useState("asc");
  const theme = useTheme();
  const isMediumOrBelow = useMediaQuery(theme.breakpoints.down("md"));

  const data = Array.from({ length: 12 }, () => ({
    progress: (Math.random() * 5).toFixed(1),
    name: "Muzahir",
  }));

  const sortedData = [...data].sort((a, b) =>
    sortOrder === "asc"
      ? parseFloat(a.progress) - parseFloat(b.progress)
      : parseFloat(b.progress) - parseFloat(a.progress)
  );

  const displayedData = isMediumOrBelow ? sortedData.slice(0, 6) : sortedData;

  return (
    <Box sx={{ margin: "4rem 0" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "1.4rem", fontWeight: 600 }}>
          Big Movers in the Last 24H
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton size="small" onClick={() => setSortOrder("desc")}>
            <FaArrowDownWideShort />
          </IconButton>
          <IconButton size="small" onClick={() => setSortOrder("asc")}>
            <FaArrowUpWideShort />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "end",
        }}
      >
        {displayedData.map((item, index) => (
          <VerticalProgress
            key={index}
            progress={item.progress}
            name={item.name}
          />
        ))}
      </Box>
    </Box>
  );
};

export default BigMovers;
