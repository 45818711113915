/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { HiOutlineMenu } from "react-icons/hi";
import { BsTrophy } from "react-icons/bs";

import { grey } from "@mui/material/colors";
import { CiShoppingCart, CiWallet, CiLogout } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import SwapToken from "../swapToken";
import Wallet from "../wallet";
import { Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ConnectWallet } from "@thirdweb-dev/react";
const DrawerMenu = () => {
  const [open, setOpen] = React.useState(false);
  const [walletOpen, setWalletOpen] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const drawerBleeding = 56;
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const toogleWallet = (newOpen) => () => {
    setWalletOpen(newOpen);
  };
  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
  }));

  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  }));

  const toggleDrawers = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        color: "#000 !important",
        height: "10%",
      }}
      role="presentation"
      onClick={toggleDrawers(anchor, false)}
      onKeyDown={toggleDrawers(anchor, false)}>
      <List>
        <ListItem sx={{ textAlign: "center", padding: 0 }}>
          <ListItemText>
            <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
              Menu
            </Typography>
          </ListItemText>
        </ListItem>
        <Divider sx={{ width: "100vw" }} />
        <ListItemButton sx={{ padding: 0 }} component={Link} to="/leaderboard">
          <ListItem sx={{ display: "flex", gap: 1 }}>
            <BsTrophy size={20} />
            <ListItemText>
              <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
                LeaderBoard
              </Typography>
            </ListItemText>
          </ListItem>
        </ListItemButton>

        <ListItemButton sx={{ padding: 0 }} onClick={toogleWallet(true)}>
          <ListItem sx={{ display: "flex", gap: 1 }}>
            <ConnectWallet />
          </ListItem>
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <HiOutlineMenu size={30} onClick={toggleDrawers(anchor, true)}>
            {anchor}
          </HiOutlineMenu>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawers(anchor, false)}
            onOpen={toggleDrawers(anchor, true)}
            sx={{
              color: "#000 !important",
              "& .css-19aawc7-MuiPaper-root-MuiDrawer-paper": {},
            }}>
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};
export default DrawerMenu;
