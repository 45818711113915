/** @format */

import "../farmProfileSlider/style.css";
import awardImg from "../../assets/Ribbon-Award-Transparent.png";

import Wrapper from "../../components/wrapper";
import {
  Typography,
  Rating,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FaShareAlt } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { COVERPAGE_BASE_URL } from "../../http/config";
import { useSelector } from "react-redux";
const FarmBackground = () => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    data: { title, image, creatorName },
  } = useSelector((state) => state?.artsSlicer?.singleArt);
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "350px",
            backgroundImage: `url('${COVERPAGE_BASE_URL + image}')`,

            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}>
          {isMobileSize ? (
            <Box display="flex" justifyContent="space-between" padding="10px">
              <IconButton
                size="small"
                sx={{ background: "white", color: "black" }}
                disableFocusRipple
                disableTouchRipple
                disableRipple>
                <FaShareAlt />
              </IconButton>
              <IconButton
                size="small"
                sx={{ background: "white", color: "black" }}
                disableFocusRipple
                disableTouchRipple
                disableRipple>
                <IoMdArrowRoundBack />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        <Wrapper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: isMobileSize ? null : "absolute",
              bottom: "20px",
              width: "94%",
            }}>
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              sx={{
                width: isMobileSize ? "100%" : null,
              }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: isMobileSize ? "100%" : null,
                  justifyContent: isMobileSize ? "space-between" : "flex-start",
                }}>
                <Box>
                  <Typography
                    variant="h1"
                    sx={{
                      color: isMobileSize ? "black" : "white",
                      fontSize: "30px",
                    }}>
                    {title}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Rating value="5" size="small" />
                  <Typography
                    variant="body1"
                    sx={{ color: isMobileSize ? "black" : "white" }}>
                    (1.8)
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography
                  variant="h1"
                  sx={{ color: isMobileSize ? "black" : "white" }}>
                  {creatorName}
                </Typography>
                <img src={awardImg} alt="award" width="25px" height="25px" />
              </Box>
            </Box>
          </Box>
        </Wrapper>
      </Box>
    </>
  );
};
export default FarmBackground;
