/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkSessionApi,
  copyTraderApi,
  creatorSignupApi,
  listInverstorApi,
  loginApi,
  signUpApi,
  unreadMessagesApi,
  verifyEmailApi,
} from "./apis";

export const login = createAsyncThunk("auth/login", async (payload) => {
  try {
    const res = await loginApi(payload);
    return res.data;
  } catch (error) {
    throw error;
  }
});

//singup

export const singup = createAsyncThunk("auth/singup", async (payload) => {
  try {
    const res = await signUpApi(payload);
    if (res.status > 201) {
      throw res.data;
    } else {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
});
export const creatorSignup = createAsyncThunk(
  "auth/creatorSignup",
  async (payload) => {
    try {
      const res = await creatorSignupApi(payload);
      if (res.status > 201) {
        throw res.data;
      } else {
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }
);
export const unreadMessages = createAsyncThunk(
  "auth/unreadMessages",
  async (payload) => {
    try {
      const res = await unreadMessagesApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
export const checkSession = createAsyncThunk(
  "auth/checkSession",
  async (payload) => {
    try {
      const token = sessionStorage.getItem("lumiShareToken");
      const res = await checkSessionApi(token);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
export const verifyEmail = createAsyncThunk(
  "auth/verifyemail",
  async (payload) => {
    try {
      const res = await verifyEmailApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
export const listInvestors = createAsyncThunk(
  "auth/listInvestors",
  async (payload) => {
    try {
      const res = await listInverstorApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
///////
export const copyTrader = createAsyncThunk(
  "auth/copyTrader",
  async (payload) => {
    try {
      const res = await copyTraderApi(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
