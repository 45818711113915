/** @format */

import { useDispatch, useSelector } from "react-redux";
import SuccessAlert from "../toaster";
import { resetDeleteToWish } from "../../redux/wishlist/wishlist.slice";
import { resetStateEdit } from "../../redux/editProfile/editProfile.slice";

const Notifications = () => {
  const dispatch = useDispatch();
  const {
    isAddWishListSuccess,
    isWishListDeleteLoading,
    isWishListDeleteSuccess,
    isWishListDeleteFailed,
    isAddWishListFailed,
    isAddWishListLoading,
    Delete,
    isReactLoading,
    isReactSuccess,
    isReactFailed,
  } = useSelector((state) => state.helpersSlicer);
  const {
    isCreatorSingupSuccess,
    isCreatorSingupLoading,
    isCreatorSingupFailed,
    isVerifyEmailLoading,
    isVerifyEmailSuccess,
    isVerifyEmailFailed,
    verifyEmail,
  } = useSelector((state) => state.auth);
  const {
    isPutOnSaleFixedSuccess,
    isPutOnSaleFixedFailed,
    isPutOnSaleFixedLoading,
  } = useSelector((state) => state.mintSlicer);
  const { messages } = useSelector((state) => state.logsSlice);
  const { isBuyArtsLoadingSuccess, isBuyArtsLoadingFailed, isBuyArtsLoading } =
    useSelector((state) => state.artsSlicer);

  if (isWishListDeleteSuccess || isWishListDeleteFailed) {
    setTimeout(() => {
      dispatch(resetDeleteToWish());
    }, 2000);
  }
  const {
    isEditProfileSuccess,
    isEditProfileFailed,
    isEditProfileLoading,
    isEditCoverLoading,
    isEditCoverSuccess,
    isEditCoverFailed,
    isEditInfoSuccess,
    isEditInfoLoading,
    isEditInfoFailed,
    editProfile,
  } = useSelector((state) => state.editProfileSlice);
  setTimeout(() => {
    if (isEditProfileSuccess || isEditCoverSuccess || isEditInfoSuccess) {
      dispatch(resetStateEdit());
    }
  }, 2000);
  return (
    <>
      {/* ADD WISH LIST ALERT */}
      {(isAddWishListSuccess ||
        isAddWishListLoading ||
        isAddWishListFailed) && (
        <SuccessAlert
          loading={isAddWishListLoading}
          success={isAddWishListSuccess}
          error={isAddWishListFailed}
          message={
            isAddWishListSuccess ? "Added To Wish List" : "Data Already exists"
          }
        />
      )}
      {/* SIGNUP SUCCESS ALERT */}
      {(isCreatorSingupSuccess ||
        isCreatorSingupLoading ||
        isCreatorSingupFailed) && (
        <SuccessAlert
          loading={isCreatorSingupLoading}
          success={isCreatorSingupSuccess}
          error={isCreatorSingupFailed}
          message={
            isCreatorSingupSuccess
              ? "Signup Success as Creator"
              : "Signup Failed as Creator"
          }
        />
      )}
      {/* VERIFY EMAIL ALERT */}
      {(isVerifyEmailSuccess ||
        isVerifyEmailLoading ||
        isVerifyEmailFailed) && (
        <SuccessAlert
          loading={isVerifyEmailLoading}
          success={isVerifyEmailSuccess}
          error={isVerifyEmailFailed}
          message={
            isVerifyEmailSuccess ? verifyEmail?.message : "Token is invalid"
          }
        />
      )}
      {/* PUTON SALE ALERT */}
      {(isPutOnSaleFixedLoading ||
        isPutOnSaleFixedFailed ||
        isPutOnSaleFixedSuccess) && (
        <SuccessAlert
          loading={isPutOnSaleFixedLoading}
          success={isPutOnSaleFixedSuccess}
          error={isPutOnSaleFixedFailed}
          message={messages[0]}
        />
      )}
      {/* DELETED FROM WISH LIST */}
      {(isWishListDeleteSuccess ||
        isWishListDeleteFailed ||
        isWishListDeleteLoading) && (
        <SuccessAlert
          loading={isWishListDeleteLoading}
          success={isWishListDeleteSuccess}
          error={isWishListDeleteFailed}
          message={Delete.message}
        />
      )}
      {/* DELETED FROM WISH LIST */}
      {(isBuyArtsLoadingSuccess ||
        isBuyArtsLoadingFailed ||
        isBuyArtsLoading) && (
        <SuccessAlert
          loading={isBuyArtsLoading}
          success={isBuyArtsLoadingSuccess}
          error={isBuyArtsLoadingFailed}
          message={messages[0]}
        />
      )}
      {/*EDIT PROFILE  */}
      {(isEditProfileSuccess ||
        isEditProfileFailed ||
        isEditProfileLoading ||
        isEditCoverLoading ||
        isEditCoverSuccess ||
        isEditCoverFailed ||
        isEditInfoLoading ||
        isEditInfoSuccess ||
        isEditInfoFailed) && (
        <SuccessAlert
          loading={
            isEditProfileLoading || isEditCoverLoading || isEditInfoLoading
          }
          success={
            isEditProfileSuccess || isEditCoverSuccess || isEditInfoSuccess
          }
          error={isEditProfileFailed || isEditCoverFailed || isEditInfoFailed}
          message={editProfile?.message}
        />
      )}
    </>
  );
};
export default Notifications;
