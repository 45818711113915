/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { leaderBoardApi, singleCreatorApi } from "./api";

export const singleCreator = createAsyncThunk(
  "single/creator",
  async (payload) => {
    try {
      const response = await singleCreatorApi(payload);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const leaderBoardData = createAsyncThunk(
  "leaderboard/creator",
  async (payload) => {
    try {
      const response = await leaderBoardApi(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
