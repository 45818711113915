/** @format */

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import FooterSection from "../FooterFirstSection";
import logo from "../../assets/logo/dark.png";
import FooterLinks from "../FooterLinks";
import image from "../../assets/footerImage.png";
import MobileMenu from "../mobileMenu";

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      {!isMobile ? (
        <Box
          sx={{ position: "relative", overflow: "hidden", paddingTop: "3vh" }}>
          <FooterSection />
          <Box
            sx={{
              paddingLeft: { lg: 5, md: 3, sm: 0 },
              paddingRight: { lg: 5, md: 3, sm: 0 },
            }}>
            <Grid container gap={1}>
              <Grid item lg={3} md={5} sm={8} xs={12}>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                  <img src={logo} alt="logo" style={{ width: "200px" }} />
                  <Typography>
                    Diving financial apportunities of billions of people in a
                    trustworthy money framework.
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2}>
                <FooterLinks heading="Mrketplace" />
              </Grid>
              <Grid item lg={2}>
                <FooterLinks heading="Links" />
              </Grid>
              <Grid item lg={2}>
                <FooterLinks heading="Join Us" />
              </Grid>
              <Grid item lg={2}>
                <FooterLinks heading="Marketplace" />
              </Grid>
            </Grid>
          </Box>
          <Box width="350px" position="absolute" top="-40px" right="-87px">
            <img src={image} width="100%" alt="/" />
          </Box>
          <Box width="506px" position="absolute" left="-106px" bottom="-378px">
            <img src={image} width="100%" alt="/" />
          </Box>
        </Box>
      ) : (
        <Box>
          <MobileMenu />
        </Box>
      )}
    </>
  );
};
export default Footer;
