/** @format */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getChatMessagesApi, getChatUsersApi, sendMessageApi } from "./api";

export const getChatUsers = createAsyncThunk(
  "chat/getChatUsers",
  async (payload) => {
    try {
      const response = await getChatUsersApi(payload);
      return response.data.users;
    } catch (error) {
      throw error;
    }
  }
);

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async (payload) => {
    try {
      const response = await sendMessageApi(payload);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getChatMessages = createAsyncThunk(
  "chat/getChatMessages",
  async (payload) => {
    try {
      const response = await getChatMessagesApi(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
