/** @format */

import { Avatar, Box, Button } from "@mui/material";

const CustomAvatar = (props) => {
  const { src, width, height, handleOpen } = props;
  const gradientStyle = {
    backgroundImage: "linear-gradient(227deg, #27D182, #FAC73F, #C2992A)",
    gap: 1,
    width: width,
    height: height,
  };

  return (
    <Box>
      <Button
        sx={{
          padding: "0px",
          minWidth: "0px",
          margin: "0px",
          "& .css-1pqm26d-MuiAvatar-img": {
            borderRadius: "50% !important",
          },
        }}
        onClick={handleOpen}>
        <Avatar src={src} sx={{ padding: "0.2rem", ...gradientStyle }} />
      </Button>
    </Box>
  );
};

export default CustomAvatar;
