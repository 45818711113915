/** @format */

import * as React from "react";
import Popover from "@mui/material/Popover";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  BiLogoLinkedin,
  BiLogoTwitter,
  BiSolidShareAlt,
  BiLogoTelegram,
  BiLogoFacebook,
  BiLogoWhatsappSquare,
} from "react-icons/bi";

import { Box, IconButton, Paper } from "@mui/material";

export default function SharePopOver({ shareUrl }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const updatedURL = "https://api.lumiplace.io" + shareUrl;

  React.useEffect(() => {
    const handleScroll = () => {
      handleClose();
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <IconButton
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{
          background: "#FFFFFF",
          color: "black",
          position: "absolute",
          left: "10px",
          top: "10px",

          "&:hover": {
            background: "white",
            boxShadow: "0px 0px 5px 1px gray",
          },
        }}>
        <BiSolidShareAlt fontSize="14px" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperComponent={(props) => (
          <Paper {...props} style={{ overflowY: "auto", maxHeight: "60vh" }} />
        )}>
        <Box
          sx={{
            padding: 0.7,
            display: "flex",
            gap: "5px",
          }}>
          <TwitterShareButton url={updatedURL}>
            <BiLogoTwitter
              fontSize="medium"
              style={{
                background: "transparent",
                "& :hover": {
                  color: "green",
                },
              }}
            />
          </TwitterShareButton>
          <TelegramShareButton url={updatedURL}>
            <BiLogoTelegram fontSize="medium" />{" "}
          </TelegramShareButton>
          <FacebookShareButton url={updatedURL}>
            {" "}
            <BiLogoFacebook fontSize="medium" />
          </FacebookShareButton>
          <WhatsappShareButton url={updatedURL}>
            {" "}
            <BiLogoWhatsappSquare fontSize="medium" />
          </WhatsappShareButton>
          <LinkedinShareButton url={updatedURL}>
            <BiLogoLinkedin fontSize="medium" />
          </LinkedinShareButton>
        </Box>
      </Popover>
    </div>
  );
}
