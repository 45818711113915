/** @format */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addVideos } from "../../redux/videos/videos.action";
import { useAddress } from "@thirdweb-dev/react-core";
import CircularProgress from "@mui/material/CircularProgress";
import { Form } from "reactstrap";
import AlertPopup from "../alerts/alertPopup";

const AddVideo = ({ handleVideoClose, open, handleAlertClose }) => {
  const [video, setVideo] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [loader, setLoader] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const dispatch = useDispatch();
  const wallet = useAddress();
  const handleClose = () => {
    setAlertOpen(false);
    handleAlertClose();
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      creator: wallet,
      title: "",
      subTitle: "",
      description: "",
      thumbnail: "",
      video: "",
    },

    onSubmit: async (values) => {
      setLoader(true);
      const fd = new FormData();
      fd.append("creator", wallet);
      fd.append("title", values.title);
      fd.append("subTitle", values.subTitle);
      fd.append("description", values.description);
      fd.append("thumbnail", thumbnail);
      fd.append("video", video);
      dispatch(addVideos(fd));
      setTimeout(() => {
        setLoader(false);
        handleVideoClose();
        setAlertOpen(true);
      }, 5000);
    },
  });
  return (
    <>
      <Dialog open={open}>
        <Form onSubmit={formik.handleSubmit}>
          <DialogTitle>Upload Video</DialogTitle>
          <DialogContent sx={{ font: "black" }}>
            <DialogContentText sx={{ color: "black", marginBottom: "10px" }}>
              Upload your Video by filling all the required fields
            </DialogContentText>
            <TextField
              sx={{ marginBottom: "10px" }}
              color="success"
              fullWidth
              label="Title"
              id="fullWidth"
              size="large"
              name="title"
              onChange={formik.handleChange}
              required
            />
            <TextField
              sx={{ marginBottom: "10px" }}
              color="success"
              fullWidth
              label="Sub-Title"
              id="fullWidth"
              name="subTitle"
              onChange={formik.handleChange}
              required
            />
            <TextField
              id="outlined-multiline-static"
              label="Description"
              color="success"
              sx={{ marginBottom: "10px" }}
              name="description"
              onChange={formik.handleChange}
              required
              multiline
              rows={4}
              fullWidth
            />
            <span>Preview Image: </span>{" "}
            <input
              type="file"
              name="thumbnail"
              accept="image/*"
              onChange={(e) => {
                const fileType = e.target.files[0].type;
                if (!fileType.startsWith("image/")) {
                  alert("Please select only Image files.");
                  e.target.value = ""; // Clear the selected file
                  return;
                }
                setThumbnail(e.target.files[0]);
              }}
            />
            <br></br>
            <span>Video: </span>{" "}
            <input
              type="file"
              name="video"
              accept="video/*"
              onChange={(e) => {
                const fileType = e.target.files[0].type;
                if (!fileType.startsWith("video/")) {
                  alert("Please select only Video files.");
                  e.target.value = ""; // Clear the selected file
                  return;
                }
                setVideo(e.target.files[0]);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="default"
              onClick={() => {
                handleVideoClose();
              }}>
              Cancel
            </Button>
            <Button variant="default" type="submit">
              {loader ? (
                <CircularProgress color="success" size={30} />
              ) : (
                "Add Video"
              )}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <AlertPopup open={alertOpen} type="success" close={handleClose} />
    </>
  );
};

export default AddVideo;
