/** @format */

import { Box } from "@mui/material";
import DrawerMenu from "../menuDrawer";
import { GrHomeRounded } from "react-icons/gr";
import { WiLightning } from "react-icons/wi";
import { CiSearch } from "react-icons/ci";
import userIcon from "../../assets/user.svg";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MobileMenu = () => {
  const classes = useStyle();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  return (
    <Box
      sx={{
        boxShadow: "0px -1px 2px #00000024;",
        padding: "10px 0 10px 0",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#fff",
        zIndex: 1000,
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}>
        <DrawerMenu />
        {isAuthenticated && (
          <Link
            to={
              user?.type === "investor"
                ? `/investor/${user?.username}`
                : `/creator/${user?.username}`
            }>
            <img src={userIcon} alt="" />
          </Link>
        )}

        <CiSearch size={30} className={classes.searchIcon} />
        <Link to="/favoritepage">
          <WiLightning size={30} style={{ color: "black" }} />
        </Link>
        <Link to="/">
          <GrHomeRounded size={25} />
        </Link>
      </Box>
    </Box>
  );
};

export default MobileMenu;
const useStyle = makeStyles(() => ({
  searchIcon: {
    color: "#000",
    "&:active": {
      color: "green",
    },
  },
}));
