/** @format */

import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { GrFormAdd } from "react-icons/gr";
import { MdRemove } from "react-icons/md";
import { accordionData } from "./data";

const TraderAccordion = () => {
  const [expandedItems, setExpandedItems] = useState([]);

  const handleAccordionChange = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([expandedItems, index]);
    }
  };

  return accordionData.map((item, index) => {
    const isExpanded = expandedItems.includes(index);
    return (
      <Accordion
        expanded={isExpanded}
        onChange={() => handleAccordionChange(index)}
        key={index}
        sx={{
          background: "none",
          borderBottom: "1px solid #E0E0E0",
          boxShadow: "none",
        }}>
        <AccordionSummary
          sx={{
            "& .MuiAccordionSummary-content": {
              display: "flex",
              gap: "5px",
              alignItems: "center",
            },
          }}>
          {isExpanded ? <MdRemove /> : <GrFormAdd />}
          <Typography variant="h2" sx={{ fontWeight: "500" }}>
            {item.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">{item.descritpion}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  });
};

export default TraderAccordion;
