/** @format */
import FarmBackground from "../../components/farmbacground";
import Details from "../../components/editfarmdetail";
import { Box } from "@mui/material";
import EditUploadVideo from "../../components/editfarmprofilevideo";
import SearchLocation from "../../components/searchlocation";

const EditFarmProfile = () => {
  return (
    <>
      <Box>
        <FarmBackground />
        <Details />
        <EditUploadVideo />
        <SearchLocation />
      </Box>
    </>
  );
};
export default EditFarmProfile;
