/** @format */
import UploadVideo from "../uploadVideo";
import { Box, Typography } from "@mui/material";
import Wrapper from "../wrapper";
const EditUploadVideo = () => {
  return (
    <Wrapper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          margin: "2rem 0",
        }}>
        <Typography variant="h1"> Farm Live 24/7</Typography>
        <UploadVideo />
      </Box>
    </Wrapper>
  );
};
export default EditUploadVideo;
