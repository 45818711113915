/** @format */

import {
  Box,
  CircularProgress,
  Dialog,
  InputBase,
  Rating,
  Typography,
} from "@mui/material";
import Button from "../button";
import { makeStyles } from "@mui/styles";
import cn from "classnames";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { putOnFixedPrice } from "../../redux/mint/mint.action";
import { inWallet, setRatings } from "../../redux/inWallet/inwallet.action";
import { Alert } from "reactstrap";
import { useAlert } from "react-alert";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import {
  useAddress,
  useChainId,
  useContract,
  useContractRead,
  useContractWrite,
  useCreateDirectListing,
} from "@thirdweb-dev/react-core";
import {
  marketplaceContractAddress,
  marketplaceContractType,
  nftContractAddress,
  nftContractType,
} from "../../hooks/thirdwebHooks/contractAddresses";

const PopupModal = ({
  className,
  open,
  head,
  buttonTitle,
  onClose = () => {},
  onClick = async () => {},
  onExited = () => {},
  tokenId,
  ownerWallet,
  amountId,
  amount,
  rating,
}) => {
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const classes = useStyles();
  const wallet = useAddress();
  const chainid = useChainId();
  const { contract: marketplace } = useContract(
    marketplaceContractAddress,
    marketplaceContractType
  );
  const { contract: nftCollection } = useContract(
    nftContractAddress,
    nftContractType
  );
  const { data } = useContractRead(nftCollection, "isApprovedForAll", [
    wallet,
    marketplaceContractAddress,
  ]);
  const { mutateAsync: setApprovalForAll } = useContractWrite(
    nftCollection,
    "setApprovalForAll"
  );
  const call = async () => {
    try {
      const approvaldata = await setApprovalForAll({
        args: [marketplaceContractAddress, true],
      });
      console.info("contract call successs", approvaldata);
    } catch (err) {
      console.error("contract call failure", err);
    }
  };

  const { mutateAsync: createDirectListing, isLoading } =
    useCreateDirectListing(marketplace);

  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderId: "",
      tokenId: tokenId,
      transactionHash: "",
      ownerWallet: ownerWallet,
      price: "",
      amountId: amountId,
      amount: amount,
      rating: 0,
    },

    onSubmit: async (values) => {
      setLoading(true);
      if (rating) {
        let data = {
          ratedBy: values.ownerWallet,
          tokenId: values.tokenId,
          amountId: values.amountId,
          rating: values.rating,
        };
        const { error, payload } = await dispatch(setRatings(data));
        if (error) {
          alert.show(error.message, {
            timeout: 3000, // custom timeout just for this one alert
            type: "error",
          });
          setLoading(false);
          onClose();
        } else {
          alert.show("Rating Added", {
            timeout: 3000, // custom timeout just for this one alert
            type: "success",
          });
          dispatch(inWallet(values.ownerWallet));
          setLoading(false);
          onClose();
        }
      } else {
        if (!data) {
          call();
        }
        try {
          const date = new Date(Date.now());
          date.setDate(date.getDate() + 365);
          const txResult = await createDirectListing({
            assetContractAddress: nftContractAddress,
            tokenId: values.tokenId,
            amount: amount,
            pricePerToken: values.price,

            startTimestamp: new Date(),
            endTimestamp: date,
          });
          const txh = txResult.receipt.transactionHash;
          const orderId = parseInt(txResult.id, 16);
          console.log(orderId, "orderid");
          const payloadApi = {
            orderId: orderId,
            tokenId: values.tokenId,
            amountId: amountId,
            chainId: chainid,
            transactionHash: txh,
            ownerWallet: wallet,
            price: values.price,
          };
          console.log(payloadApi, "payloadApi");
          const { error, payload } = await dispatch(
            putOnFixedPrice(payloadApi)
          );
          dispatch(inWallet(wallet));
          setLoading(false);
          onClose();
        } catch (err) {
          console.log(err.message);
        }
      }
    },
  });
  const { isPutOnSaleFixedLoading, isPutOnSaleFixedSuccess } = useSelector(
    (state) => state.mintSlicer
  );

  return (
    <Dialog
      fullWidth={true}
      open={open}
      className={cn(classes.root, className)}
      TransitionProps={{
        onExited,
      }}>
      <div className="wrapper">
        <div className="mainHeading">
          <Typography variant="h6" className={classes.head}>
            {head}
          </Typography>
          <div className="icon">
            <IconButton onClick={onClose}>
              <CancelIcon />
            </IconButton>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {rating ? (
            <div className="content">
              <Rating
                size="large"
                name="rating"
                value={formik.values.rating}
                onChange={formik.handleChange}
                sx={{ marginBottom: "20px" }}
              />
            </div>
          ) : (
            <div className="content">
              <InputBase
                placeholder="Enter Your Price"
                type="number"
                name="price"
                value={formik.values.price}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
                    formik.handleChange(e);
                  }
                }}
                sx={{
                  border: `1px solid #cacaca`,
                  padding: "5px",
                  borderRadius: "5px",
                  width: "100%",
                }}
              />
              <Typography
                variant="body1"
                sx={{ fontSize: 16, fontWeight: "500" }}>
                LUMI
              </Typography>
            </div>
          )}

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {isPutOnSaleFixedLoading ? (
              <>
                <CircularProgress color="success" size={20} />
              </>
            ) : (
              <Button
                type="submit"
                disabled={loading}
                sx={{
                  backgroundColor: "#34C77B !important",
                  textTransform: "capitalize",
                  color: "#fff !important",
                  width: "50%",
                  border: "none",
                }}
                onClick={onClick}>
                {loading ? <CircularProgress size={30} /> : buttonTitle}
              </Button>
            )}
          </Box>
        </form>
      </div>
    </Dialog>
  );
};

export default PopupModal;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      fontFamily: "'Poppins', sans-serif",
    },
    boxSizing: "border-box",
    userSelect: "none",
    "& .MuiPaper-root": {
      width: 300,
      height: 250,
    },

    "& .wrapper": {
      boxSizing: "border-box",
      padding: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
      width: "100%",
      textAlign: "center",
    },

    "& .content": {
      display: "flex",
      alignItems: "center",
      paddingTop: 50,
      paddingBottom: 20,
      gap: 2,
      justifyContent: "center",
    },

    "& .mainHeading": {
      position: "relative",
      width: "100%",
      borderBottom: "1px solid #eaeaea",
      textAlign: "center",
    },
    "& .icon": {
      position: "absolute",
      right: -5,
      top: -15,
    },

    "& .button": {
      backgroundColor: "#0A1A72",
      borderRadius: 15,
      color: "#FFFFFF",
      minWidth: 100,
      fontFamily: "Tajawal,sans-serif",
      fontWeight: "700",
      maxWidth: 200,
      width: "100%",
      margin: "auto",
    },
  },
  head: {
    fontFamily: "Tajawal, sans-serif",
    fontSize: 24,
    fontWeight: 700,
  },
}));
