/** @format */

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Paper, Typography } from "@mui/material";
import { FaFileAlt, FaFilePdf } from "react-icons/fa";

function FileUploader({ formik, value }) {
  const classes = useStyles();
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileName, setFileName] = useState(""); // State to store the file name

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (!file.type.includes("pdf") && !file.type.includes("spreadsheetml")) {
        setErrorMessage(
          "Invalid file type. Please select a PDF or Excel file."
        );
        return;
      }
      setErrorMessage("");
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);

    formik.setFieldValue(value, file);
    const truncatedName =
      file.name.length > 10 ? file.name.substring(0, 5) + "..." : file.name;
    setFileName(truncatedName);
  };

  return (
    <>
      <div className={classes.upload}>
        <input
          type="file"
          id={value}
          accept=""
          name={value}
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <div className={classes.width}>
          <label htmlFor={value} className={classes.uploadLabel}>
            <div className={classes.container}>
              <FaFileAlt className={classes.outlineCamera} />
              <span className={classes.button}>Upload</span>
            </div>
          </label>
          <Paper
            elevation={0}
            sx={{
              padding: previewImage === null ? 2 : 1,
              border: previewImage === null ? "1px solid #E5E8EB" : "none",
            }}>
            {previewImage ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "13px",
                  border: "1px solid #E5E8EB",
                }}>
                <FaFilePdf
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "#900603",
                  }}
                />

                {fileName && (
                  <Typography sx={{ fontSize: "12px" }}>{fileName}</Typography>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "40px",
                  height: "40px",
                }}>
                <Typography sx={{ textAlign: "center" }}>Preview</Typography>
              </Box>
            )}
          </Paper>
        </div>
      </div>

      {errorMessage && (
        <Typography sx={{ color: "red" }} variant="caption" mt={1}>
          {errorMessage}
        </Typography>
      )}
    </>
  );
}

export default FileUploader;

const useStyles = makeStyles((theme) => ({
  width: {
    border: "1px dashed #E5E8EB",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 15px",
    borderRadius: "8px",
    overflow: "hidden", // Ensure the image doesn't overflow
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  outlineCamera: {
    border: "1px solid #E5E8EB",
    padding: "8px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  button: {
    border: "1px solid #E5E8EB",
    background: "#FAFCFF 0% 0%",
    borderRadius: "5px",
    padding: "8px",
    fontWeight: "500",
    fontSize: "13px",
  },
  uploadLabel: {
    cursor: "pointer",
    display: "flex",
  },
}));
