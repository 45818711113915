import React, { useState } from "react";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";

const VolumeControl = () => {
  const [isMuted, setIsMuted] = useState(false);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div style={{ cursor: "pointer" }}>
      {isMuted ? (
        <FaVolumeMute onClick={toggleMute} />
      ) : (
        <FaVolumeUp onClick={toggleMute} />
      )}
    </div>
  );
};

export default VolumeControl;
