/** @format */

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";

export default function WalletTable({ data, minters, handleApprove }) {
  const lowerCaseArray = minters.map((str) => str.toLowerCase());
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: "black", color: "#fff" }}>
          <TableRow>
            <TableCell
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#fff",
              }}>
              Wallet Address
            </TableCell>
            <TableCell
              align="left"
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#fff",
              }}>
              Username
            </TableCell>
            <TableCell
              align="left"
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#fff",
              }}>
              Status
            </TableCell>
            <TableCell
              align="left"
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#fff",
              }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {data.map((items) => {
            return (
            
            );
          })} */}
          {data.map(
            (
              { walletAddress, username, status, approval, creatorID },
              index
            ) => {
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}>
                    {walletAddress}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}>
                    {username}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}>
                    {lowerCaseArray.includes(walletAddress)
                      ? "Already Approved"
                      : "Waiting for Approval"}
                  </TableCell>
                  <TableCell align="left">
                    {lowerCaseArray.includes(walletAddress) ? (
                      <Button disabled variant="outlined">
                        Approved
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          handleApprove(walletAddress);
                        }}
                        variant="contained">
                        Approve
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
