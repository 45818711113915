/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { minted, putOnFixedPrice } from "./mint.action";

const initialState = {
  isMintingFailed: false,
  isMintingLoading: false,
  isMintingSuccess: false,
  Minted: {},
  isPutOnSaleFixedFailed: false,
  isPutOnSaleFixedLoading: false,
  isPutOnSaleFixedSuccess: false,
  IsPutOnSale: {},
};

const mintSlicer = createSlice({
  name: "minting",
  initialState,
  reducers: {
    resetStateMint: (state) => {
      state.isPutOnSaleFixedSuccess = false;
      state.isPutOnSaleFixedLoading = false;
      state.isPutOnSaleFixedFailed = false;
      state.isMintingSuccess = false;
      state.isMintingLoading = false;
      state.isMintingFailed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(minted.pending, (state) => {
      state.isMintingLoading = true;
    });
    builder.addCase(minted.fulfilled, (state, action) => {
      state.isMintingLoading = false;
      state.isMintingSuccess = true;
      state.Minted = action.payload;
    });
    builder.addCase(minted.rejected, (state) => {
      state.isMintingLoading = false;
      state.isMintingFailed = true;
      state.isMintingSuccess = false;
    });
    ////////////////////////////////
    builder.addCase(putOnFixedPrice.pending, (state, action) => {
      state.isPutOnSaleFixedLoading = true;
    });
    builder.addCase(putOnFixedPrice.fulfilled, (state, action) => {
      state.isPutOnSaleFixedLoading = false;
      state.IsPutOnSale = action.payload;
      state.isPutOnSaleFixedSuccess = true;
    });
    builder.addCase(putOnFixedPrice.rejected, (state) => {
      state.isPutOnSaleFixedLoading = false;
      state.isPutOnSaleFixedFailed = true;
      state.isPutOnSaleFixedSuccess = false;
    });
  },
});

export const { resetStateMint, resetState } = mintSlicer.actions;
export default mintSlicer.reducer;
