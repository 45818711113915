/** @format */

import React, { useState } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { makeStyles } from "@mui/styles";
import { Paper, Typography } from "@mui/material";

function ImageUpload({ formik, value }) {
  const classes = useStyles();
  const [previewImage, setPreviewImage] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");

  const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (!allowedImageTypes.includes(file.type)) {
        setErrorMessage(
          "Invalid file type. Please select a JPEG, PNG, or GIF image."
        );
        return;
      }

      setErrorMessage("");

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);

      formik.setFieldValue(value, file);
    }
  };
  console.log(formik.values);
  return (
    <>
      <div className={classes.upload}>
        <input
          type="file"
          id={value}
          accept="image/*"
          name={value}
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <div className={classes.width}>
          <label htmlFor={value} className={classes.uploadLabel}>
            <div className={classes.container}>
              <AiOutlineCamera className={classes.outlineCamera} />
              <span className={classes.button}>Upload</span>
            </div>
          </label>
          <Paper
            elevation={0}
            sx={{
              padding: previewImage === null ? 2.5 : 0,
              border: "1px solid #E5E8EB",
            }}>
            {previewImage ? (
              <img
                src={previewImage}
                alt="Preview"
                style={{ width: "60px", height: "60px", borderRadius: "8px" }}
              />
            ) : (
              <BsImage />
            )}
          </Paper>
        </div>
      </div>
      {errorMessage && (
        <Typography color="error" variant="caption" mt={1}>
          {errorMessage}
        </Typography>
      )}
    </>
  );
}

export default ImageUpload;

const useStyles = makeStyles((theme) => ({
  width: {
    border: "1px dashed #E5E8EB",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 15px",
    borderRadius: "8px",
    overflow: "hidden", // Ensure the image doesn't overflow
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  outlineCamera: {
    border: "1px solid #E5E8EB",
    padding: "8px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  button: {
    border: "1px solid #E5E8EB",
    background: "#FAFCFF 0% 0%",
    borderRadius: "5px",
    padding: "8px",
    fontWeight: "500",
    fontSize: "13px",
  },
  uploadLabel: {
    cursor: "pointer",
    display: "flex",
  },
}));
