/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { leaderBoardData, singleCreator } from "./singleCreator.action";

const initialState = {
  isSingleCreatorLoading: false,
  isSingleCreatorLoadingFailed: false,
  isSingleCreatorLoadingSuccess: false,
  creator: {},
  isLeaderBoardDataLoading: false,
  isLeaderBoardDataFailed: false,
  leaderBoard: {
    topInvestors: [],
    topCreators: [],
  },
};

const singleCreatorSlice = createSlice({
  name: "single",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(singleCreator.pending, (state) => {
      state.isSingleCreatorLoading = true;
    });
    builder.addCase(singleCreator.fulfilled, (state, action) => {
      console.log(action.payload.data);
      state.isSingleCreatorLoading = false;
      state.isSingleCreatorLoadingSuccess = true;
      state.creator = action.payload;
    });
    builder.addCase(singleCreator.rejected, (state) => {
      state.isSingleCreatorLoading = false;
      state.isSingleCreatorLoadingFailed = true;
    });
    ////////////////////////////////////////////
    builder.addCase(leaderBoardData.pending, (state) => {
      state.isLeaderBoardDataLoading = true;
    });
    builder.addCase(leaderBoardData.fulfilled, (state, action) => {
      state.isLeaderBoardDataLoading = false;
      state.leaderBoard = action.payload;
    });
    builder.addCase(leaderBoardData.rejected, (state, action) => {
      state.isLeaderBoardDataLoading = false;
      state.isLeaderBoardDataFailed = true;
    });
  },
});

export default singleCreatorSlice.reducer;
