/** @format */

import { createSlice } from "@reduxjs/toolkit";
import {
  checkSession,
  creatorSignup,
  listInvestors,
  login,
  singup,
  verifyEmail,
  unreadMessages,
} from "./auth.actions";

const initialState = {
  isAuthenticated: false,
  isAuthenticatedloading: false,
  isAuthenticatedFailed: false,
  walletAddress: null,
  isLoginSuccess: false,
  isLoginFailed: false,
  user: {},
  isSignupLoading: false,
  isSignupLoadingFailed: false,
  isSignupSuccess: false,
  signupError: {},
  singup: {},
  isCheckSessionLoading: false,
  isCheckSessionLoadingFailed: false,
  isCheckSessionSuccess: false,
  isCreatorSingupLoading: false,
  isCreatorSingupSuccess: false,
  isCreatorSingupFailed: false,
  creatorSignupError: {},
  creator: {},
  messages: null,
  isUnreadMessagesLoading: false,
  isUnreadMessagesSuccess: false,
  isUnreadMessageslFailed: false,
  isVerifyEmailLoading: false,
  isVerifyEmailSuccess: false,
  isVerifyEmailFailed: false,
  verifyEmail: {},
  isListInvestorLoading: false,
  isListInvestorSuccess: false,
  isListInvestorFailed: false,
  investorList: {},
};

const authSuccess = (state, action) => {
  state.isAuthenticated = true;
  sessionStorage.setItem("lumiShareToken", action?.payload?.walletAddress);
  state.user = action.payload.user;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthentication: (state, action) => {
      state.walletAddress = action.payload.walletAddress;
    },
    ResetState: (state) => {
      state.isAuthenticatedloading = false;
      state.user = {};
      state.isAuthenticatedFailed = false;
      state.isLoginSuccess = false;
      state.isAuthenticated = false;
      sessionStorage.removeItem("lumiShareToken");
    },
    resetLoginState: (state) => {
      state.isAuthenticatedFailed = false;
    },
    singupResetState: (state) => {
      state.isSignupSuccess = false;
      state.isSignupLoading = false;
      state.isCreatorSingupLoading = false;
      state.isCreatorSingupSuccess = false;
      state.isCreatorSingupFailed = false;
      state.isSignupLoadingFailed = false;
      state.signupError = {};
      state.singup = {};
      state.creatorSignupError = {};
    },
    resetVerifyState: (state) => {
      state.isVerifyEmailLoading = false;
      state.isVerifyEmailSuccess = false;
      state.isVerifyEmailFailed = false;
      state.verifyEmail = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isAuthenticatedloading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      authSuccess(state, action);
      state.isAuthenticatedloading = false;
      state.user = action.payload;
      state.isLoginSuccess = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.isAuthenticatedloading = false;
      state.isAuthenticatedFailed = true;
      state.isLoginSuccess = false;
      state.isLoginFailed = true;
    });
    //unreadMessages
    builder.addCase(unreadMessages.pending, (state) => {
      state.isUnreadMessagesLoading = true;
      state.isUnreadMessagesSuccess = false;
      state.isUnreadMessagesFailed = false;
    });
    builder.addCase(unreadMessages.fulfilled, (state, action) => {
      state.isUnreadMessagesLoading = false;
      state.isUnreadMessagesSuccess = true;
      state.isUnreadMessagesFailed = false;
      state.messages = action.payload.unreadMsgs;
    });
    builder.addCase(unreadMessages.rejected, (state) => {
      state.isUnreadMessagesLoading = false;
      state.isUnreadMessagesSuccess = false;
      state.isUnreadMessagesFailed = true;
    });
    // signup
    builder.addCase(singup.pending, (state) => {
      state.isSignupLoading = true;
    });
    builder.addCase(singup.fulfilled, (state, action) => {
      state.isSignupLoading = false;
      state.isSignupSuccess = true;
      state.singup = action.payload;
    });
    builder.addCase(singup.rejected, (state, action) => {
      state.isSignupLoading = false;
      state.isSignupLoadingFailed = true;
      state.signupError = action.error;
    });
    ///////////////////////////
    builder.addCase(creatorSignup.pending, (state) => {
      state.isCreatorSingupLoading = true;
    });
    builder.addCase(creatorSignup.fulfilled, (state, action) => {
      state.isCreatorSingupLoading = false;
      state.isCreatorSingupSuccess = true;
      state.creator = action.payload;
    });
    builder.addCase(creatorSignup.rejected, (state, action) => {
      state.isCreatorSingupLoading = false;
      state.isCreatorSingupSuccess = false;
      state.isCreatorSingupFailed = false;
      state.creatorSignupError = action.error;
    });
    //////////////////////////////////
    builder.addCase(checkSession.pending, (state) => {
      state.isCheckSessionLoading = true;
    });
    builder.addCase(checkSession.fulfilled, (state, action) => {
      authSuccess(state, action);
      state.isCheckSessionLoading = false;
      state.user = action.payload;
      state.isLoginSuccess = true;
    });
    builder.addCase(checkSession.rejected, (state, action) => {
      state.isCheckSessionLoading = false;
      state.isCheckSessionLoadingFailed = true;
      state.isCheckSessionSuccess = false;
    });
    ////////////////////////////////

    builder.addCase(verifyEmail.pending, (state) => {
      state.isVerifyEmailLoading = true;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.isVerifyEmailLoading = false;
      state.isVerifyEmailSuccess = true;
      state.verifyEmail = action.payload;
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.isVerifyEmailLoading = false;
      state.isVerifyEmailSuccess = false;
      state.isVerifyEmailFailed = true;
    });
    /////////////////////////////
    builder.addCase(listInvestors.pending, (state) => {
      state.isListInvestorLoading = true;
    });
    builder.addCase(listInvestors.fulfilled, (state, action) => {
      state.isListInvestorLoading = false;
      state.isListInvestorSuccess = true;
      state.investorList = action.payload;
    });
    builder.addCase(listInvestors.rejected, (state) => {
      state.isListInvestorLoading = false;
      state.isListInvestorSuccess = false;
      state.isListInvestorFailed = true;
    });
  },
});

export const {
  setAuthentication,
  ResetState,
  resetLoginState,
  singupResetState,
  resetVerifyState,
} = authSlice.actions;

export default authSlice.reducer;
