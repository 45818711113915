/** @format */

import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import NFTArtsCard from "../NFTcard";
import Loading from "../loading";
import "./style.css";
import { useState } from "react";
import Carousel from "react-elastic-carousel";
import { CarouselArrow } from "../../utiles/units";
const TopCards = ({ title, margin, singleCardView }) => {
  const { nftData } = useSelector((state) => state.artsSlicer.arts);
  const { isArtsDataLoadingSuccess } = useSelector((state) => state.artsSlicer);
  const [currentIndex, setCurrentIndex] = useState(0);
  const nextClick = (key) => {
    key = key + 1;
    if (nftData.length === key) {
      key = 0;
    }
    setCurrentIndex(key);
  };
  if (singleCardView) {
    var breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 1 },
      { width: 768, itemsToShow: 1 },
      { width: 1200, itemsToShow: 1 },
    ];
  } else {
    var breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 768, itemsToShow: 4 },
      { width: 1200, itemsToShow: 4 },
    ];
  }

  return (
    <Box margin={margin} display="flex" flexDirection="column" gap="10px">
      <Box>
        <Typography variant="h1" sx={{ paddingLeft: "40px" }}>
          {title}
        </Typography>
      </Box>

      {!isArtsDataLoadingSuccess ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Loading />
        </Box>
      ) : (
        <Box>
          <Carousel
            autoPlaySpeed={3000}
            enableAutoPlay={singleCardView ? false : true}
            disableArrowsOnEnd={false}
            breakPoints={breakPoints}
            infinite={true}
            showArrows={singleCardView ? false : true}
            pagination={false}
            renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}>
            {nftData &&
              nftData.length > 0 &&
              nftData.map((data, i) =>
                singleCardView ? (
                  currentIndex === i && (
                    <NFTArtsCard
                      key={i}
                      no={i}
                      {...data}
                      singleCardView={singleCardView}
                      onNextClick={nextClick}
                    />
                  )
                ) : (
                  <NFTArtsCard
                    key={i}
                    {...data}
                    singleCardView={singleCardView}
                    onNextClick={nextClick}
                  />
                )
              )}
          </Carousel>
        </Box>
      )}
    </Box>
  );
};

export default TopCards;
