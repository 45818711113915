/** @format */

import React from "react";
import AvatarComponent from "../storiesAvatar";
import { Typography, Box } from "@mui/material";

const StoryHeaderComponent = ({ name, url, time }) => (
  <Box
    display="flex"
    sx={{ position: "fixed", left: "34.5%", top: "7.5%", zIndex: 1 }}>
    <AvatarComponent url={url} />
    <Box marginLeft="5px" sx={{ display: "flex", gap: 0.5 }}>
      <Typography fontWeight="500" color="white" sx={{ fontSize: "12px" }}>
        {name}
      </Typography>
      <Typography variant="caption" fontWeight="400" color="white">
        {`${time}`}
      </Typography>
    </Box>
  </Box>
);

export default StoryHeaderComponent;
