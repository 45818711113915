/** @format */

import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { BsPerson } from "react-icons/bs";
import { WiLightning } from "react-icons/wi";
import logo from "../../assets/logo/dark.png";
import SearchInput from "../searchInput";
import { CiWallet } from "react-icons/ci";
import { Link } from "react-router-dom";
import CustomModal from "../modal";
import Login from "../walletSwapper";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { unreadMessages } from "../../redux/auth/auth.actions";
import { ConnectWallet } from "@thirdweb-dev/react";
const Header = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { user, isLoginSuccess, messages } = useSelector((state) => state.auth);

  return (
    <>
      {!isMobile ? (
        <AppBar
          position="static"
          sx={{
            boxShadow:
              "rgba(50, 34, 72, 0.25) 0px -2px 13px 0px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          }}>
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: "center",
              height: "70px",
            }}>
            <Box
              sx={{
                width: { xs: "100%", md: "20%" },
                textAlign: { xs: "center", md: "left" },
                alignItems: "center",
              }}>
              <Link to="/" sx={{}}>
                <img src={logo} alt="Logo" style={{ width: "70%" }} />
              </Link>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                width: "20%",
                position: "relative",
              }}>
              <SearchInput />
            </Box>
            <List
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",

                alignItems: "center",
              }}>
              <ListItem
                component={Link}
                to="/leaderboard"
                sx={{ color: "black", display: "block" }}>
                <Typography
                  color="inherit"
                  // component={Link}
                  // to="/leaderboard"
                  sx={{
                    textDecoration: "none",
                    textTransform: "capitalize",
                    fontWeight: "bolder",
                  }}>
                  LeaderBoard
                </Typography>
              </ListItem>
              {!isLoginSuccess ? (
                <ListItem
                  sx={{ color: "black", display: "block" }}
                  variant="h3">
                  <CustomModal />
                </ListItem>
              ) : (
                user?.type === "creator" && (
                  <ListItem
                    component={Link}
                    to="/minting"
                    sx={{ color: "black", display: "block" }}>
                    <Typography
                      sx={{
                        textDecoration: "none !important",
                        fontWeight: "bolder",
                        textTransform: "capitalize",
                      }}>
                      Create
                    </Typography>
                  </ListItem>
                )
              )}
              {isLoginSuccess ? (
                <>
                  {" "}
                  <ListItem component={Link} to={"/chat"}>
                    <Badge badgeContent={messages} color="success">
                      <MailIcon color="action" />
                    </Badge>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to={
                      user?.type === "investor"
                        ? `/investor/${user?.username}`
                        : `/creator/${user?.username}`
                    }
                    sx={{ color: "black" }}>
                    <BsPerson color="black" fontSize="25px" />
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/favoritepage"
                    sx={{ color: "black" }}>
                    <WiLightning fontSize="25px" />
                  </ListItem>
                </>
              ) : (
                ""
              )}
              <ListItem sx={{ color: "black" }}>
                <ConnectWallet theme="light" />
              </ListItem>
            </List>
          </Container>
        </AppBar>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow:
              "rgba(50, 34, 72, 0.25) 0px -2px 13px 0px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            padding: 1,
          }}>
          <Link to="/">
            <img src={logo} alt="Logo" style={{ width: "200px" }} />
          </Link>
        </Box>
      )}
    </>
  );
};

export default Header;
