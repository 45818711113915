/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { addVideos, getVideo } from "./videos.action";

const initialState = {
  isVideosLoading: false,
  isVideosLoadingError: false,
  isVideosLoadingSuccess: false,
  videos: [],

  addVideoLoading: false,
  addVideoLoadingError: false,
  addVideoLoadingSuccess: false,
};

const videosSlicer = createSlice({
  name: "videos",
  initialState: initialState,
  reducers: {
    resetStateBuy: (state) => {
      state.isVideosLoading = false;
      state.isVideosLoadingError = false;
      state.isVideosLoadingSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVideo.pending, (state) => {
      state.isVideossLoading = true;
      state.isVideosLoadingError = false;
      state.isVideosLoadingSuccess = false;
    });
    builder.addCase(getVideo.fulfilled, (state, action) => {
      state.isVideossLoading = false;
      state.isVideosLoadingError = false;
      state.isVideosLoadingSuccess = true;
      state.videos = action.payload;
    });
    builder.addCase(getVideo.rejected, (state) => {
      state.isVideossLoading = false;
      state.isVideosLoadingError = true;
      state.isVideosLoadingSuccess = false;
    });
    builder.addCase(addVideos.pending, (state) => {
      state.addArticlesLoading = true;
      state.addArticlesLoadingError = false;
      state.addArticlesLoadingSuccess = false;
    });
    builder.addCase(addVideos.fulfilled, (state, action) => {
      state.addArticlesLoading = false;
      state.addArticlesLoadingError = false;
      state.addArticlesLoadingSuccess = true;
    });
    builder.addCase(addVideos.rejected, (state) => {
      state.addArticlesLoading = false;
      state.addArticlesLoadingError = true;
      state.addArticlesLoadingSuccess = false;
    });
  },
});

export const { resetStateBuy } = videosSlicer.actions;

export default videosSlicer.reducer;
