/** @format */

import { useDispatch, useSelector } from "react-redux";
import InvestorCard from "../../components/investorCard";
import { copyTrader, listInvestors } from "../../redux/auth/auth.actions";
import { useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";

const CopyTrader = () => {
  const {
    isListInvestorLoading,
    isAuthenticated,
    user: { walletAddress },
    investorList: { investors },
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listInvestors());
  }, [dispatch]);
  return (
    <Box p={4}>
      <Box py={2}>
        <Typography sx={{ textAlign: "center" }} variant="h1">
          The Investor's
        </Typography>
        <Divider sx={{ padding: "20px 0" }} />
      </Box>
      {isListInvestorLoading ? (
        ""
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}>
          {investors?.map((items) => {
            return (
              <>
                <InvestorCard
                  {...items}
                  login={isAuthenticated}
                  user={walletAddress}
                />
              </>
            );
          })}{" "}
        </Box>
      )}
    </Box>
  );
};
export default CopyTrader;
