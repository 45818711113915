/** @format */

import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../header";
import Footer from "../footer/index";
import loadingImage from "../../assets/logoLoading.gif";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getArticle } from "../../redux/articles/articles.action";
import { getVideo } from "../../redux/videos/videos.action";
const Layout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getArticle());
    dispatch(getVideo());
  }, []);

  return (
    <Box>
      <Header />
      <Box sx={{ pb: { xs: 8, lg: 0 } }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
