/** @format */

import React, { Component } from "react";
import Avatar from "../chatList/Avatar";
import { PROFILE_BASE_URL } from "./../../http/config";
import { timeAgo } from "./../../utiles/index.js";
const MessageItem = ({ msg, user, image, date }) => {
  return (
    <div
      style={{ animationDelay: `0.8s` }}
      className={`chat__item ${user ? user : ""}`}>
      <div className="chat__item__content">
        <div className="chat__msg">{msg}</div>
        <div className="chat__meta">
          <span>{timeAgo(date)}</span>
          {/* <span>Seen 1.03PM</span> */}
        </div>
      </div>
      <Avatar isOnline="active" image={PROFILE_BASE_URL + image} />
    </div>
  );
};
export default MessageItem;
