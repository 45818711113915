/** @format */

import { Box, Typography } from "@mui/material";
import { data } from "../Articles/data";
import Videos from "../videos";
import "./styles.css";

import Carousel from "react-elastic-carousel";
import { CarouselArrow } from "../../utiles/units";
import { useSelector } from "react-redux";

const VideosCard = ({ video, margin, singleCardView }) => {
  const { videos, isVideosLoadingSuccess } = useSelector(
    (state) => state.videos
  );
  const nftDataLength = videos.length;
  if (singleCardView) {
    var breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 1 },
      { width: 768, itemsToShow: 1 },
      { width: 1200, itemsToShow: 1 },
    ];
  } else {
    var breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 768, itemsToShow: 4 },
      { width: 1200, itemsToShow: 4 },
    ];
  }
  const renderVideos = () => {
    // Add this line for debugging

    return (
      <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Carousel
          disableArrowsOnEnd={false}
          breakPoints={breakPoints}
          infinite={true}
          pagination={false}
          renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}>
          {videos &&
            videos.map((nftData, i) => (
              <Videos key={nftData.id} {...nftData} />
            ))}
        </Carousel>
      </div>
    );
  };

  return (
    <Box
      margin={margin}
      display="flex"
      flexDirection="column"
      gap="10px"
      justifyContent="center">
      <Box>
        <Typography variant="h1" sx={{ paddingLeft: "40px" }}>
          {video}
        </Typography>
      </Box>
      <Box sx={{ padding: "0px 0px" }}>{renderVideos()}</Box>
    </Box>
  );
};

export default VideosCard;
