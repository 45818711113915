/** @format */

import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import Chart from "../Chart";

const BarCharts = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      padding="0px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        flexWrap="wrap">
        <Chart />
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "row" : "column",
            width: isSmallScreen ? "100%" : null,
            justifyContent: "space-between",
          }}>
          <Box>
            <Typography variant="body1" sx={{ color: "#9DA4AA" }}>
              Daily Outputs:
            </Typography>
            <Typography variant="h2">54.09kWh</Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: "#9DA4AA" }}>
              Monthly Outputs:
            </Typography>
            <Typography variant="h2">54.09kWh</Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: "#9DA4AA" }}>
              Total Outputs:
            </Typography>
            <Typography variant="h2">54.09kWh</Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: "#9DA4AA" }}>
              Co, avoidance:
            </Typography>
            <Typography variant="h2">54.09kWh</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        gap="5px"
        flexWrap="wrap"
        justifyContent="space-between">
        <Box display="flex" gap="5px" alignItems="center">
          <Box
            width="15px"
            height="15px"
            sx={{ backgroundColor: "#FEDB33" }}></Box>
          <Typography>PV System 93 kWh</Typography>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Box
            width="15px"
            height="15px"
            sx={{ backgroundColor: "#FDC10E" }}></Box>
          <Typography>PV System 93 kWh</Typography>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Box
            width="15px"
            height="15px"
            sx={{ backgroundColor: "#E99511" }}></Box>
          <Typography>PV System 93 kWh</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default BarCharts;
