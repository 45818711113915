/** @format */

import { lumiShare } from "../../http/config";

export const loginApi = async (payload) => {
  try {
    const res = await lumiShare.post("app.v1/api/login", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

//singup

export const signUpApi = async (payload) => {
  try {
    const res = await lumiShare.post("/app.v1/api/investerSignUp", payload);
    return res;
  } catch (error) {
    return error.response;
  }
};
export const unreadMessagesApi = async (payload) => {
  try {
    const res = await lumiShare.get(
      "/app.v1/api/newMessages?wallet=" + payload
    );
    return res;
  } catch (error) {
    return error.response;
  }
};
export const creatorSignupApi = async (payload) => {
  try {
    const res = await lumiShare.post("/app.v1/api/creatorSignup", payload);
    return res;
  } catch (error) {
    return error.response;
  }
};
// check Session

export const checkSessionApi = async (payload) => {
  try {
    const res = await lumiShare.get(`/app.v1/api/checkSession/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};

//////// Verify Email Address /////////////////////

export const verifyEmailApi = async (payload) => {
  try {
    const res = await lumiShare.get(`/app.v1/api/verifyUser/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};

///////////////////////////////////////////////
export const listInverstorApi = async (payload) => {
  try {
    const res = await lumiShare.get(`/app.v1/api/admin/listInvestors`);
    return res;
  } catch (error) {
    throw error;
  }
};
//////////
export const copyTraderApi = async (payload) => {
  try {
    const res = await lumiShare.post(`/app.v1/api/copyDetails`, payload);
    return res;
  } catch (error) {
    throw error;
  }
};
