/** @format */

import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";
import akib from "../../assets/image.png";
const VerticalProgress = ({ progress, name }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Typography variant="body2" color="#34C77B">
        {`${progress}%`}
      </Typography>

      <Box sx={{ height: `${progress * 40}px` }}>
        <LinearProgress
          variant="determinate"
          value={100}
          sx={{
            width: "50px",
            height: "100%",
            borderRadius: 1,
            backgroundColor: "green",
            color: "#34C77B",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#34C77B",
            },
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: "#34C77B",
            },
            "& .MuiLinearProgress-colorPrimary": {
              backgroundColor: "lightgray",
            },
          }}
        />
      </Box>

      <img src={akib} alt="" style={{ width: "50px" }} />
      <Typography>john</Typography>
    </Box>
  );
};

export default VerticalProgress;
