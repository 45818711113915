/** @format */

import { Box, Typography } from "@mui/material";
import Button from "../button";
import { useState } from "react";

const ExclusiveSelection = ({ onButtonSelect }) => {
  const [selectedButton, setSelectedButton] = useState(false);
  const handleButtonClick = (value) => {
    if (selectedButton === value) {
      setSelectedButton(null);
      onButtonSelect(null);
    } else {
      setSelectedButton(value);
      onButtonSelect(value);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        padding: "30px 0 30px 0",
      }}>
      {" "}
      <Box>
        {" "}
        <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
          Choose your profile type
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          sx={{
            border: "1px solid #E5E8EB",
            background:
              selectedButton === "investor"
                ? "#FAC73F 0% 0% !important"
                : "#FAFCFF !important",
            textTransform: "capitalize",
            borderRadius: "8px",
            color: "black !important",
          }}
          onClick={() => handleButtonClick("investor")}>
          investor
        </Button>
        <Button
          sx={{
            border: "1px solid #E5E8EB",
            background:
              selectedButton === "creator"
                ? "#FAC73F 0% 0% !important"
                : "#FAFCFF !important",
            textTransform: "capitalize",
            borderRadius: "8px",
            color: "black !important",
          }}
          onClick={() => handleButtonClick("creator")}>
          creator
        </Button>
      </Box>
    </Box>
  );
};

export default ExclusiveSelection;
