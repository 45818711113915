/** @format */

import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import { Box } from "@mui/material";
import { PROFILE_BASE_URL } from "./../../http/config";
import { useDispatch } from "react-redux";
import { getChatMessages } from "../../redux/chat/chats.action";
import { useEffect, useState } from "react";
import { unreadMessages } from "../../redux/auth/auth.actions";
const ChatListItems = ({
  username,
  active,
  img,
  isOnline,
  selectChat,
  selected,
  walletAddress,
  user,
  handleUser,
  creatorID,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (selected) {
      const payload = {
        toAddress: walletAddress,
        fromAddress: user,
      };
      dispatch(getChatMessages(payload));
      dispatch(unreadMessages(user));
    }
  }, [selected, dispatch, user, walletAddress]);
  const handleChange = () => {
    handleUser(creatorID);
  };
  return (
    <>
      <Box
        sx={{
          background: selected ? "#eeeeee" : "none",
          margin: "0px !important",
        }}
        style={{ animationDelay: `0.5s` }}
        onClick={handleChange}
        className={`chatlist__item ${active ? active : ""} `}>
        <Avatar
          image={img ? PROFILE_BASE_URL + img : "http://placehold.it/80x80"}
          isOnline={isOnline}
        />

        <Box sx={{ textDecoration: "none" }} className="userMeta">
          <p>{username}</p>
          <span className="activeTime">{active}</span>
        </Box>
      </Box>
    </>
  );
};
export default ChatListItems;
