/** @format */

import Slider from "react-slick";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import "../farmProfileSlider/style.css";
import awardImg from "../../assets/Ribbon-Award-Transparent.png";
import Button from "../../components/button";
import { IoVideocam } from "react-icons/io5";
import Wrapper from "../../components/wrapper";
import {
  Typography,
  Rating,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FaShareAlt } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { COVERPAGE_BASE_URL } from "../../http/config";

const ImageSlider = () => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const settings = {
    swipeToSlide: true,
    accessibility: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    nextArrow: <GrFormNext />,
    prevArrow: <GrFormPrevious />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { data } = useSelector((state) => state?.artsSlicer?.singleArt);
  const imageCover = data?.image;

  const imgSrc = `https://lumiplace.infura-ipfs.io/ipfs/${
    imageCover.split("//")[1]
  }`;

  return (
    <Box sx={{ position: "relative" }}>
      <Slider {...settings}>
        <Box
          sx={{
            width: "100%",
            minHeight: "400px",
            backgroundImage: `url('${imageCover}')`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}>
          {isMobileSize ? (
            <Box display="flex" justifyContent="space-between" padding="10px">
              <IconButton
                size="small"
                sx={{ background: "white", color: "black" }}
                disableFocusRipple
                disableTouchRipple
                disableRipple>
                <FaShareAlt />
              </IconButton>
              <IconButton
                size="small"
                sx={{ background: "white", color: "black" }}
                disableFocusRipple
                disableTouchRipple
                disableRipple>
                <IoMdArrowRoundBack />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Slider>
    </Box>
  );
};
export default ImageSlider;
