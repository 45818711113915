/** @format */

import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { COVERPAGE_BASE_URL, PROFILE_BASE_URL } from "../../http/config";
import { CardActions, CircularProgress } from "@mui/material";
import Button from "./../button";
import MainButton from "../buttonTwo";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { copyTrader } from "../../redux/auth/auth.actions";
import { useAlert } from "react-alert";
const InvestorCard = ({
  username,
  img,
  createdAt,
  bio,
  cover,
  walletAddress,
  login,
  user,
  handlecopytraderApi,
}) => {
  const [handlecopytrader, sethandlecopytrader] = useState(false);
  const dateObject = new Date(createdAt);
  const dateOnly = dateObject.toISOString().slice(0, 10);
  const maxLength = 300;
  const longText = bio; // Assuming bio contains the long text
  let trimmedText = "";
  const dispatch = useDispatch();
  const alert = useAlert();
  const handleCopyTrader = async () => {
    sethandlecopytrader(true);
    const data = {
      fromAddress: walletAddress,
      toAddress: user,
    };

    const { error, payload } = await dispatch(copyTrader(data));
    if (error) {
      alert.show(error.message, {
        timeout: 5000, // custom timeout just for this one alert
        type: "error",
      });
    } else {
      sethandlecopytrader(false);
      alert.show(payload.message, {
        timeout: 5000, // custom timeout just for this one alert
        type: "success",
      });
    }
  };
  if (longText) {
    trimmedText = longText.substring(0, maxLength);
    if (longText.length > maxLength) {
      trimmedText += "..."; // Add ellipsis if the text is longer than the maximum length
    }
  } else {
    trimmedText = ""; // or any default value you prefer
  }
  return (
    <Card sx={{ width: "20vw", marginTop: "20px" }}>
      <CardHeader
        avatar={<Avatar src={PROFILE_BASE_URL + img} aria-label="recipe" />}
        color="secondary"
        title={
          <span
            style={{
              color: "black",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}>
            {username}
          </span>
        }
        subheader={
          <span
            style={{
              color: "black",
              textTransform: "capitalize",
            }}>
            {dateOnly}
          </span>
        }
      />
      <CardMedia
        component="img"
        height="194"
        image={PROFILE_BASE_URL + cover}
        alt="Paella dish"
      />
      <CardContent>
        <Typography
          sx={{ textTransform: "capitalize", height: "100px" }}
          variant="body2"
          color="secondary">
          {trimmedText}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton aria-label="add to favorites">
          <Button
            variant="contained"
            disabled={!login}
            onClick={handleCopyTrader}>
            {handlecopytrader ? (
              <CircularProgress color="success" size="30px"></CircularProgress>
            ) : (
              "  Copy trader"
            )}
          </Button>
        </IconButton>
      </CardActions>
    </Card>
  );
};
export default InvestorCard;
