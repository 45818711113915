/** @format */

import {
  Box,
  Typography,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LinearDeterminate from "../progressBar";
import Wrapper from "../wrapper";
import MainButton from "../buttonTwo";
import { MdOutlineExpandMore } from "react-icons/md";
import { AiOutlineInstagram } from "react-icons/ai";
import {
  BiLogoDiscordAlt,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiSolidShareAlt,
} from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoVideocam } from "react-icons/io5";
import awardImg from "../../assets/Ribbon-Award-Transparent.png";

const ProfileDetails = () => {
  const {
    user: { walletAddress },
  } = useSelector((state) => state.auth);
  const {
    data: { ownerWallet, tokenId },
  } = useSelector((state) => state?.artsSlicer?.singleArt);
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const socialIcons = [
    { icon: <AiOutlineInstagram fontSize="medium" />, label: "Instagram" },
    { icon: <BiLogoDiscordAlt fontSize="medium" />, label: "Discord" },
    { icon: <BiLogoLinkedin fontSize="medium" />, label: "LinkedIn" },
    { icon: <BiLogoTwitter fontSize="medium" />, label: "Twitter" },
    { icon: <BiSolidShareAlt fontSize="medium" />, label: "Share" },
  ];
  const { data } = useSelector((state) => state.artsSlicer.singleArt);

  return (
    <>
      <Wrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2rem",
          }}>
          <Box
            sx={{
              width: isMobileSize ? "100%" : "500px",
            }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box>
                <Typography
                  sx={{ fontSize: "25px", color: "grey" }}
                  variant="h1">
                  {data?.title}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ color: "grey" }} variant="h2">
                  by {data?.username}
                </Typography>
              </Box>
              <Box>
                <Typography>{data?.description}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: isMobileSize ? "wrap" : null,
                  gap: "5px",
                  width: "100%",
                }}>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: isMobileSize ? "300px" : null,
                      display: "flex",
                      gap: "20px",
                      justifyContent: isMobileSize ? "space-between" : null,
                    }}>
                    <Box>
                      <Typography variant="h2">Price</Typography>
                      <Typography variant="h2" sx={{ color: "#34C77B" }}>
                        {data?.fixedprice} LUMI
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: isMobileSize ? "100%" : null }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      justifyContent: isMobileSize ? "space-between" : null,
                      width: isMobileSize ? "100%" : null,
                    }}>
                    <Box>
                      {ownerWallet && walletAddress === ownerWallet ? (
                        <MainButton
                          variant="contained"
                          component={Link}
                          to={`/editfarmprofile/${tokenId}`}
                          sx={{
                            backgroundColor: "#34C77B !important",
                            BoxShadow: "none !important",
                            fontSize: "15px",
                            width: "fit-content",
                            color: "white",
                            minWidth: isMobileSize ? "100%" : "100px",
                            textDecoration: "none",
                          }}>
                          Edit profile
                        </MainButton>
                      ) : (
                        <MainButton
                          variant="contained"
                          component={Link}
                          to={`/portfolio/${data?.tokenId}/${data?.amountId}`}
                          sx={{
                            backgroundColor: "#34C77B !important",
                            BoxShadow: "none !important",
                            fontSize: "15px",
                            width: "fit-content",
                            color: "white",
                            minWidth: isMobileSize ? "100%" : "100px",
                            textDecoration: "none",
                          }}>
                          Buy Now
                        </MainButton>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box display="flex" alignItems="center" gap="5px">
                  <Typography variant="h3">The Offered Yield (APY)</Typography>
                  <Typography variant="h3"> {data?.apy}%</Typography>
                </Box>
                <LinearDeterminate />
              </Box>
              <Box>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: isMobileSize ? "300px" : null,
                      display: "flex",
                      gap: "20px",
                      margin: "5px",
                    }}>
                    <Box width="50%">
                      <Typography variant="h2">Country</Typography>
                      <Typography variant="h2" sx={{ color: "#34C77B" }}>
                        {data?.country}
                      </Typography>
                    </Box>
                    <Box width="50%">
                      <Typography variant="h2">Project Size</Typography>
                      <Typography variant="h2" sx={{ color: "#34C77B" }}>
                        {data?.projectSize}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: isMobileSize ? "300px" : null,
                      display: "flex",
                      gap: "20px",
                      margin: "5px",
                      justifyContent: isMobileSize ? "space-between" : null,
                    }}>
                    <Box width="50%">
                      <Typography variant="h2">Years In Operation</Typography>
                      <Typography variant="h2" sx={{ color: "#34C77B" }}>
                        {" "}
                        {data?.yearsInOperation}
                      </Typography>
                    </Box>
                    <Box width="50%">
                      <Typography variant="h2">Capital Invested</Typography>
                      <Typography variant="h2" sx={{ color: "#34C77B" }}>
                        {data?.capitalInvested}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: isMobileSize ? "300px" : null,
                      display: "flex",
                      margin: "5px",
                      gap: "20px",
                      justifyContent: isMobileSize ? "space-between" : null,
                    }}>
                    <Box width="50%">
                      <Typography variant="h2">Mezzanine Financing</Typography>
                      <Typography
                        variant="h2 "
                        sx={{ color: "#34C77B", fontWeight: "bold" }}>
                        {data?.mezzanineFinancing}
                      </Typography>
                    </Box>
                    <Box width="50%">
                      <Typography variant="h2">Use Of Proceeds</Typography>
                      <Typography variant="h2" sx={{ color: "#34C77B" }}>
                        {data?.useOfProceeds}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: isMobileSize ? "300px" : null,
                      display: "flex",
                      gap: "20px",
                      margin: "5px",
                      justifyContent: isMobileSize ? "space-between" : null,
                    }}>
                    <Box width="50%">
                      <Typography variant="h2">
                        Yield On Mezzanine Debt
                      </Typography>
                      <Typography variant="h2" sx={{ color: "#34C77B" }}>
                        {" "}
                        {data?.yieldOnMezzanineDebt}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {isMobileSize ? null : (
            <Box>
              {socialIcons.map((socialIcon, index) => (
                <IconButton
                  key={index}
                  component={Link}
                  sx={{
                    color: "black",
                    width: "fit-content",
                  }}
                  aria-label={socialIcon.label}>
                  {socialIcon.icon}
                </IconButton>
              ))}
            </Box>
          )}
        </Box>
      </Wrapper>
    </>
  );
};
export default ProfileDetails;
