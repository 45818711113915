/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { storiesData, storiesPostData } from "./stories.action";

const initialState = {
  isStoriesLoading: false,
  isStoriesLoadingError: false,
  isStoriesLoadingSuccess: false,
  stories: {},
  ////////////////////////////
  isStoriesPostLoading: false,
  isStoriesPostSuccess: false,
  isStoriesPostFailed: false,
  postStories: {},
};

const storiesSlice = createSlice({
  name: "stories",
  initialState: initialState,
  reducers: {
    resetStories: (state) => {
      state.isStoriesPostSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storiesData.pending, (state) => {
      state.isStoriesLoading = true;
    });
    builder.addCase(storiesData.fulfilled, (state, action) => {
      state.isStoriesLoading = false;
      state.stories = action.payload;
      state.isStoriesLoadingSuccess = true;
    });
    builder.addCase(storiesData.rejected, (state) => {
      state.isStoriesLoading = false;
      state.isStoriesLoadingError = true;
      state.isStoriesLoadingSuccess = false;
    });
    /////////////////////////////////////////////
    builder.addCase(storiesPostData.pending, (state) => {
      state.isStoriesPostLoading = true;
    });
    builder.addCase(storiesPostData.fulfilled, (state, action) => {
      state.isStoriesPostLoading = false;
      state.postStories = action.payload;
      state.isStoriesPostSuccess = true;
    });
    builder.addCase(storiesPostData.rejected, (state) => {
      state.isStoriesPostLoading = false;
      state.isStoriesPostFailed = true;
      state.isStoriesPostSuccess = false;
    });
  },
});
export const { resetStories } = storiesSlice.actions;

export default storiesSlice.reducer;
