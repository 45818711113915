/** @format */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addVideo, getVideos } from "./api";

export const addVideos = createAsyncThunk(
  "videos/addVideos",
  async (payload) => {
    try {
      const response = await addVideo(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getVideo = createAsyncThunk("videos/getVideo", async (payload) => {
  try {
    const response = await getVideos();
    return response.data;
  } catch (error) {
    throw error;
  }
});
