/** @format */

import { combineReducers } from "@reduxjs/toolkit";
import authSlicer from "../redux/auth/auth.slicer";
import singleCreatorSlice from "../redux/singleCreator/singleCreator.slicer";
import mintSlicer from "../redux/mint/mint.slicer";
import inwalletSlicer from "../redux/inWallet/inwallet.slicer";
import artsSlicer from "../redux/arts/arts.slicer";
import editProfileSlice from "../redux/editProfile/editProfile.slice";

import storiesSlicer from "../redux/stories/stories.slicer";
import logsSlicer from "../redux/arts/logs.slicer";
import helpersSlicer from "../redux/wishlist/wishlist.slice";
import chatsSlicer from "../redux/chat/chats.slicer";
import videosSlicer from "../redux/videos/videos.slicer";
import articlesSlicer from "../redux/articles/articles.slicer";
const rootReducer = combineReducers({
  auth: authSlicer,
  singleCreator: singleCreatorSlice,
  mintSlicer: mintSlicer,
  inWalletSlicer: inwalletSlicer,
  artsSlicer: artsSlicer,
  editProfileSlice: editProfileSlice,
  storiesSlice: storiesSlicer,
  logsSlice: logsSlicer,
  helpersSlicer: helpersSlicer,
  chat: chatsSlicer,
  videos: videosSlicer,
  articles: articlesSlicer,
});

export default rootReducer;
