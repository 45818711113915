/** @format */

import React from "react";
import { Box, Paper, Rating, Typography } from "@mui/material";
import CustomAvatar from "../avatar";
import { Link } from "react-router-dom";
import { PROFILE_BASE_URL } from "../../http/config";
const Investor = ({ username, img, total_amount, ratings }) => {
  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "0.8rem",
        marginBottom: "1rem",
        justifyContent: "space-between",
        padding: "0.7rem 1rem",
        textDecoration: "none",
      }}
      component={Link}
      to={`/investor/${username}`}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Box>
          <CustomAvatar
            alt=""
            src={PROFILE_BASE_URL + img}
            width="55px"
            height="55px"
          />
        </Box>
        <Typography variant="h3" component="h2">
          {username}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Rating value={ratings} readOnly />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        {total_amount.toFixed(3)}
      </Box>
    </Paper>
  );
};

export default Investor;
