/** @format */

import { Avatar, Box, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { PROFILE_BASE_URL } from "../../http/config";
import { useSelector } from "react-redux";
const UserStory = ({ handleOpen }) => {
  const {
    user: { img },
  } = useSelector((state) => state.auth);
  return (
    <Box>
      <Button
        sx={{
          padding: "0px",
          minWidth: "0px",
          margin: "0px",
          "& .css-1pqm26d-MuiAvatar-img": {
            borderRadius: "50% !important",
          },
        }}>
        <Avatar
          src={PROFILE_BASE_URL + img}
          sx={{
            padding: "0.2rem",
            backgroundImage:
              "linear-gradient(227deg, #27D182, #FAC73F, #C2992A)",
            gap: 1,
            width: "55px",
            height: "55px",
          }}
          onClick={handleOpen}
        />
        <IconButton
          sx={{
            zIndex: 1,
            position: "absolute",
            left: "65%",
            top: "65%",
            backgroundColor: "black",
            width: "20px",
            height: "20px",
            "&:hover": {
              backgroundColor: "#2f2e2e",
            },
          }}>
          <AddIcon sx={{ fontSize: "16px", color: "white" }} />
        </IconButton>
      </Button>
    </Box>
  );
};

export default UserStory;
