/** @format */

import { Box, InputBase, Typography } from "@mui/material";
import { useFormik } from "formik";
import Input from "../input";
import Button from "../button";
import { useEffect } from "react";
import { Form, FormFeedback } from "reactstrap";
import * as Yup from "yup";

const CreatorDetails = ({
  handleNext,
  onFormDetailsChange,
  handleBack,
  formik,
}) => {
  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     companyName: "",
  //     registrationNumber: "",
  //     companyEmail: "",
  //     designation: "",
  //     physicalAddress: "",
  //     websiteUrl: "",
  //     contactPerson: "",
  //     companyDetails: "",
  //     projectLocation: "",
  //     estimatedValue: "",
  //     tokenizationObjective: "",
  //     APYRange: "",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: async (values) => {
  //     try {
  //       const fd = new FormData();
  //       fd.append("companyName", values.companyName);
  //       fd.append("registrationNumber", values.registrationNumber);
  //       fd.append("companyEmail", values.companyEmail);
  //       fd.append("phoneNumber", values.phoneNumber);
  //       fd.append("physicalAddress", values.physicalAddress);
  //       fd.append("websiteUrl", values.websiteUrl);
  //       fd.append("contactPerson", values.contactPerson);
  //       fd.append("designation", values.designation);
  //       fd.append("companyDetails", values.companyDetails);
  //       fd.append("projectLocation", values.projectLocation);
  //       fd.append("estimatedValue", values.estimatedValue);
  //       fd.append("tokenizationObjective", values.tokenizationObjective);
  //       fd.append("APYRange", values.APYRange);
  //     } catch (error) {
  //       console.error("signup error", error);
  //     }
  //   },
  // });
  useEffect(() => {
    onFormDetailsChange(formik.values);
  }, [formik.values, onFormDetailsChange]);
  return (
    <Box
      sx={{
        height: "340px",
        overflowY: "auto",
        paddingRight: "4px",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#DEE2E6",
          borderRadius: "3px",
        },
      }}>
      <Form onSubmit={formik.handleSubmit}>
        <Box sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Company Name
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <Input
              name="companyName"
              type="companyName"
              className="form-control pe-5"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyName}
              invalid={
                formik.touched.companyName && formik.errors.companyName
                  ? true
                  : false
              }
            />
            {formik.touched.companyName && formik.errors.companyName ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.companyName}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Registration Number
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <Input
              name="registrationNumber"
              type="registrationNumber"
              className="form-control pe-5"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.registrationNumber}
              invalid={
                formik.touched.share && formik.errors.share ? true : false
              }
            />
            {formik.touched.registrationNumber &&
            formik.errors.registrationNumber ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.registrationNumber}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Contact Information
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <Input
              name="phoneNumber"
              type="phoneNumber"
              className="form-control pe-5"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              invalid={
                formik.touched.share && formik.errors.share ? true : false
              }
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <FormFeedback type="invalid" style={{ fontSize: "10px" }}>
                {formik.errors.phoneNumber}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Company Email
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <Input
              name="companyEmail"
              type="companyEmail"
              className="form-control pe-5"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyEmail}
              invalid={
                formik.touched.share && formik.errors.share ? true : false
              }
            />
            {formik.touched.companyEmail && formik.errors.companyEmail ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.companyEmail}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Desigination
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <Input
              name="designation"
              type="designation"
              className="form-control pe-5"
              onChange={formik.handleChange}
              value={formik.values.designation}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.share && formik.errors.share ? true : false
              }
            />
            {formik.touched.designation && formik.errors.designation ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.designation}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box py={0.5} sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Physical Address
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <InputBase
              name="physicalAddress"
              type="physicalAddress"
              className="form-control pe-5"
              onChange={formik.handleChange}
              value={formik.values.physicalAddress}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.physicalAddress && formik.errors.physicalAddress
                  ? true
                  : false
              }
              sx={{
                background: "#FAFCFF 0% 0% no-repeat padding-box !important",
                border: "1px solid #E5E8EB",
                borderRadius: "8px",
                opacity: 1,
                width: "100%",
                padding: "8px",
              }}
            />
            {formik.touched.physicalAddress && formik.errors.physicalAddress ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.physicalAddress}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box py={0.5} sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Website URL
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <InputBase
              name="websiteUrl"
              type="websiteUrl"
              className="form-control pe-5"
              onChange={formik.handleChange}
              value={formik.values.websiteUrl}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.websiteUrl && formik.errors.websiteUrl
                  ? true
                  : false
              }
              sx={{
                background: "#FAFCFF 0% 0% no-repeat padding-box !important",
                border: "1px solid #E5E8EB",
                borderRadius: "8px",
                opacity: 1,
                width: "100%",
                padding: "8px",
              }}
            />
            {formik.touched.websiteUrl && formik.errors.websiteUrl ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.websiteUrl}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box py={0.5} sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Contact Person
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <InputBase
              name="contactPerson"
              type="contactPerson"
              className="form-control pe-5"
              onChange={formik.handleChange}
              value={formik.values.contactPerson}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.contactPerson && formik.errors.contactPerson
                  ? true
                  : false
              }
              sx={{
                background: "#FAFCFF 0% 0% no-repeat padding-box !important",
                border: "1px solid #E5E8EB",
                borderRadius: "8px",
                opacity: 1,
                width: "100%",
                padding: "8px",
              }}
            />
            {formik.touched.contactPerson && formik.errors.contactPerson ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.contactPerson}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box py={0.5} sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Company Details
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <InputBase
              name="companyDetails"
              type="companyDetails"
              className="form-control pe-5"
              onChange={formik.handleChange}
              value={formik.values.companyDetails}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.companyDetails && formik.errors.companyDetails
                  ? true
                  : false
              }
              sx={{
                background: "#FAFCFF 0% 0% no-repeat padding-box !important",
                border: "1px solid #E5E8EB",
                borderRadius: "8px",
                opacity: 1,
                width: "100%",
                padding: "8px",
              }}
            />
            {formik.touched.companyDetails && formik.errors.companyDetails ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.companyDetails}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>

        <Box py={0.5} sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Project Location
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <InputBase
              name="projectLocation"
              type="projectLocation"
              className="form-control pe-5"
              onChange={formik.handleChange}
              value={formik.values.projectLocation}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.projectLocation && formik.errors.projectLocation
                  ? true
                  : false
              }
              sx={{
                background: "#FAFCFF 0% 0% no-repeat padding-box !important",
                border: "1px solid #E5E8EB",
                borderRadius: "8px",
                opacity: 1,
                width: "100%",
                padding: "8px",
              }}
            />
            {formik.touched.projectLocation && formik.errors.projectLocation ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.projectLocation}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box py={0.5} sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Estimated Value
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <InputBase
              name="estimatedValue"
              type="estimatedValue"
              className="form-control pe-5"
              onChange={formik.handleChange}
              value={formik.values.estimatedValue}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.estimatedValue && formik.errors.estimatedValue
                  ? true
                  : false
              }
              sx={{
                background: "#FAFCFF 0% 0% no-repeat padding-box !important",
                border: "1px solid #E5E8EB",
                borderRadius: "8px",
                opacity: 1,
                width: "100%",
                padding: "8px",
              }}
            />
            {formik.touched.estimatedValue && formik.errors.estimatedValue ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.estimatedValue}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box py={0.5} sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Tokenization Objectve
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <InputBase
              name="tokenizationObjective"
              type="tokenizationObjective"
              className="form-control pe-5"
              onChange={formik.handleChange}
              value={formik.values.tokenizationObjective}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.tokenizationObjective &&
                formik.errors.tokenizationObjective
                  ? true
                  : false
              }
              sx={{
                background: "#FAFCFF 0% 0% no-repeat padding-box !important",
                border: "1px solid #E5E8EB",
                borderRadius: "8px",
                opacity: 1,
                width: "100%",
                padding: "8px",
              }}
            />
            {formik.touched.tokenizationObjective &&
            formik.errors.tokenizationObjective ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.tokenizationObjective}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box py={0.5} sx={{ width: "100%" }}>
          <label style={{ color: "#8f979e", fontSize: "14px" }}>
            Percentage
          </label>
          <Box py={0.5} sx={{ width: "100%" }}>
            <InputBase
              name="APYRange"
              type="APYRange"
              className="form-control pe-5"
              onChange={formik.handleChange}
              value={formik.values.APYRange}
              onBlur={formik.handleBlur}
              invalid={
                formik.touched.APYRange && formik.errors.APYRange ? true : false
              }
              sx={{
                background: "#FAFCFF 0% 0% no-repeat padding-box !important",
                border: "1px solid #E5E8EB",
                borderRadius: "8px",
                opacity: 1,
                width: "100%",
                padding: "8px",
              }}
            />
            {formik.touched.APYRange && formik.errors.APYRange ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "10px", color: "red", textAlign: "center" }}>
                {formik.errors.APYRange}
              </FormFeedback>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: ".5fr 3fr",
            justifyContent: "space-between",
            gap: 1,
          }}>
          <Button
            onClick={() => {
              handleBack();
            }}
            sx={{
              backgroundColor: "grey !important",
              textTransform: "capitalize",
              color: "#fff !important",
            }}>
            Back
          </Button>
          {formik.values.APYRange === "" ||
          formik.values.companyDetails === "" ||
          formik.values.companyEmail === "" ||
          formik.values.contactPerson === "" ||
          formik.values.designation === "" ||
          formik.values.estimatedValue === "" ||
          formik.values.physicalAddress === "" ||
          formik.values.registrationNumber === "" ||
          formik.values.websiteUrl === "" ||
          formik.values.tokenizationObjective === "" ||
          formik.values.companyName === "" ||
          formik.values.projectLocation === "" ? (
            <Typography sx={{ textAlign: "center", color: "red" }} py={1}>
              Fill Required Fields
            </Typography>
          ) : (
            <Button
              onClick={() => {
                handleNext(formik);
              }}
              sx={{
                backgroundColor: "#34C77B !important",
                textTransform: "capitalize",
                color: "#fff !important",
              }}>
              Next
            </Button>
          )}
        </Box>
      </Form>
    </Box>
  );
};

export default CreatorDetails;
const validationSchema = Yup.object({
  companyName: Yup.string().required("Company Name is required"),
  registrationNumber: Yup.string().required("Registration Number is required"),
  phoneNumber: Yup.string().required("Contact Information is required"),
  companyEmail: Yup.string()
    .email("Invalid email address")
    .required("Company Email is required"),
  designation: Yup.string().required("Designation is required"),
  physicalAddress: Yup.string().required("Physical Address is required"),
  websiteUrl: Yup.string()
    .url("Invalid URL")
    .required("Website URL is required"),
  contactPerson: Yup.string().required("Contact Person is required"),
  companyDetails: Yup.string().required("Company Details is required"),
  projectLocation: Yup.string().required("Project Location is required"),
  estimatedValue: Yup.number()
    .typeError("Estimated Value must be a number")
    .required("Estimated Value is required"),
  tokenizationObjective: Yup.string().required(
    "Tokenization Objective is required"
  ),
});
