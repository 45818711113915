/** @format */

import { lumiShare } from "../../http/config";

export const inWalletApi = async (payload) => {
  console.log("Inwallet api payload: ", payload);
  try {
    const res = await lumiShare.get(`app.v1/api/Inwallet/${payload}`);

    return res;
  } catch (error) {
    throw error;
  }
};
export const allCreators = async (payload) => {
  try {
    const res = await lumiShare.get(`app.v1/api/allCreators`);

    return res;
  } catch (error) {
    throw error;
  }
};

export const collectionsApi = async (payload) => {
  try {
    const res = await lumiShare.get(`app.v1/api/collections/${payload}`);

    return res;
  } catch (error) {
    throw error;
  }
};
export const setRatingsApi = async (payload) => {
  try {
    const res = await lumiShare.post(`app.v1/api/rateNft`, payload);

    return res;
  } catch (error) {
    throw error;
  }
};
