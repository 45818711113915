/** @format */

import { Box, Paper, Rating, Typography, useMediaQuery } from "@mui/material";
import SharePopOver from "../shareButton/index";
import "./style.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomAvatar from "../avatar";
import LinearDeterminate from "../progressBar";
import { PROFILE_BASE_URL } from "../../http/config";
import { useEffect, useState } from "react";
import PopupModal from "../popupSale";
import Button from "../button";
import CollectionPopup from "../collectionPopup";

const CreatedCards = ({
  image,
  username,
  title,
  creatorImg,
  created_at,
  amount,
  tokenId,
  ownerWallet,
  ownerId,
  amountId,
  fixedprice,
  remainingAmount,
  creatorWallet,
  apy,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isIPFSImg = /ipfs:\/\//.test(image),
    imgSrc = isIPFSImg
      ? `https://lumiplace.infura-ipfs.io/ipfs/${image.split("//")[1]}`
      : image;
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const { walletAddress } = useSelector((state) => state.auth.user);

  const { isPutOnOnsaleFixedSuccess } = useSelector(
    (state) => state.mintSlicer
  );
  useEffect(() => {
    if (isPutOnOnsaleFixedSuccess) {
      setIsOpen(false);
    }
  }, [setIsOpen, isPutOnOnsaleFixedSuccess]);

  const originalDate = new Date(created_at);

  const month = originalDate.getMonth() + 1;
  const day = originalDate.getDate();
  const year = originalDate.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          textDecoration: "none",
          width: isMobile ? "100%" : "20vw",
          borderRadius: "10px",

          // overflow: "visible !important",
          marginBottom: "10px !important",
        }}>
        <Box
          sx={{
            position: "relative",
            overflow: "visible !important",
          }}>
          <Link to={`/artpieces/${tokenId}`}>
            <img
              style={{
                width: "100%",
                height: "200px",
                borderRadius: "10px 10px 0 0px",
                objectFit: "cover",
              }}
              src={imgSrc}
              alt=""
            />
          </Link>
          <Box>
            <SharePopOver shareUrl={`/farmprofile/${tokenId}/${amountId}`} />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              position: "absolute",
              bottom: "0px",
              padding: "10px 10px",
              boxSizing: "border-box",
            }}>
            <Box>
              <Typography variant="h2" sx={{ color: "white" }}>
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
              padding: "10px",
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                width: "fit-content",
              }}>
              <Box>
                <CustomAvatar
                  src={`${PROFILE_BASE_URL + creatorImg}`}
                  width="30px"
                  height="30px"
                />
              </Box>
              <Box>
                <Typography
                  component={Link}
                  to={`/creator/${username}`}
                  variant="h4"
                  sx={{
                    textDecoration: "none",
                    color: "#000 !important",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}>
                  {username}
                </Typography>
                <Typography variant="body1" sx={{ color: "black" }}>
                  {formattedDate}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Rating
                precision={1}
                value={3}
                size="small"
                sx={{ fontSize: "12px" }}
              />
              <Typography
                variant="body1"
                sx={{ color: "white", textAlign: "end" }}>
                {3}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 10px 10px 10px",
            }}>
            <Box>
              <Typography variant="body1">Price</Typography>
              <Typography variant="h3">{fixedprice}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "0 10px" }}>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "space-between",
            }}>
            <Typography variant="body">The Offered Yield (APY)</Typography>
            <Typography variant="body">{apy}%</Typography>
          </Box>
          <Box sx={{ padding: "10px 0px" }}>
            <LinearDeterminate />
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            borderRadius: "0px 0px 15px 15px",
          }}>
          <Box>
            {remainingAmount > 0 ? (
              <Button variant="outlined" onClick={openModal}>
                Put On Sale
              </Button>
            ) : (
              <Button variant="outlined">On Sale</Button>
            )}
          </Box>
        </Box>
        <CollectionPopup
          open={isOpen}
          onClose={closeModal}
          head="Are you sure?"
          buttonTitle="Proceed"
          ownerWallet={creatorWallet}
          tokenId={tokenId}
          ownerId={ownerId}
          amountId={amountId}
          amount={remainingAmount}
        />
      </Paper>
    </>
  );
};
export default CreatedCards;
