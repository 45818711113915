/** @format */

import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home";
import Layout from "../components/layout";
import Wrapper from "../components/wrapper";
import LeaderBoard from "../pages/LeaderBoard";
import TranderLanding from "../pages/TraderLanding";
import Portfolio from "../pages/portfolio";
import FavoritePage from "../pages/favoritePage";
import CreatorPage from "../pages/creatorPage";
import FarmProfile from "../pages/farmProfile";
import InverstorProfile from "../pages/investorProfile";
import Mint from "../pages/mintPage";
import EditProfile from "../pages/editProfile";
import EditFarmProfile from "../pages/editfarmprofile";
import VerifyEmail from "../pages/verifyEmail";
import ArtPieces from "../pages/ArtPiece";
import CopyTrader from "../pages/copyTrader";
import ChatPage from "../pages/chat";
import Article from "../pages/article";
import MintingRole from "../components/adminPage";
const router = createBrowserRouter([
  {
    element: <Layout />,
    path: "/",
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/leaderboard",
        element: (
          <Wrapper>
            <LeaderBoard />
          </Wrapper>
        ),
      },
      {
        path: "/tradelanding",
        element: <TranderLanding />,
      },
      {
        path: "/portfolio/:tokenID/:amountId",
        element: (
          <Wrapper>
            <Portfolio />
          </Wrapper>
        ),
      },
      {
        path: "/investor/:username",
        element: <InverstorProfile />,
      },
      {
        path: "/favoritepage",
        element: (
          <Wrapper>
            <FavoritePage />
          </Wrapper>
        ),
      },
      {
        path: "/article/:articleId",
        element: (
          <Wrapper>
            <Article />
          </Wrapper>
        ),
      },
      {
        path: "/verifyemail/:token",
        element: (
          <Wrapper>
            <VerifyEmail />
          </Wrapper>
        ),
      },
      {
        path: "/creator/:id",
        element: <CreatorPage />,
      },
      {
        path: "/farmprofile/:tokenID/:amountId",
        element: <FarmProfile />,
      },
      {
        path: "/minting",
        element: <Mint />,
      },
      {
        path: "/chat",
        element: <ChatPage />,
      },
      {
        path: "/editProfile/:username",
        element: <EditProfile />,
      },
      {
        path: "/editfarmprofile/:id",
        element: <EditFarmProfile />,
      },
      {
        path: "/artpieces/:tokenId",
        element: (
          <Wrapper>
            {" "}
            <ArtPieces />
          </Wrapper>
        ),
      },
      {
        path: "/copytrader",
        element: (
          <Wrapper>
            {" "}
            <CopyTrader />
          </Wrapper>
        ),
      },
      {
        path: "/admin",
        element: (
          <Wrapper>
            {" "}
            <MintingRole />
          </Wrapper>
        ),
      },
    ],
  },
]);
export default router;
