/** @format */
import { Box, InputBase, Divider, Typography } from "@mui/material";
import MainButton from "../../components/buttonTwo";
import { useFormik } from "formik";
import { editInfo } from "../../redux/editProfile/editProfile.action";
import { useDispatch, useSelector } from "react-redux";
import Button from "../button";
const EditForm = () => {
  const { data } = useSelector((state) => state.singleCreator.creator);
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      walletAddress: data?.walletAddress,
      bio: data?.bio,
      instagram: data?.instagram,
      discord: data?.discord,
      linkedin: data?.linkedin,
      twitter: data?.twitter,
    },

    onSubmit: (values) => {
      dispatch(editInfo(values));
    },
  });
  return (
    <>
      <Box>
        <Box sx={{ margin: "20px 0px" }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
            Edit Profile
          </Typography>
          <Divider />
        </Box>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                width: "400px",
                padding: "11px 0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                marginBottom: "80px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "100%",
                }}>
                <label for="bio" style={{ fontSize: "14px" }}>
                  Bio
                </label>
                <InputBase
                  value={formik.values.bio}
                  name="bio"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    border: "1px solid #cacaca",
                    width: "100%",
                    borderRadius: "5px",
                    padding: 0.8,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "100%",
                }}>
                <label for="insta" style={{ fontSize: "14px" }}>
                  Instagram
                </label>
                <InputBase
                  value={formik.values.instagram}
                  name="instagram"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    border: "1px solid #cacaca",
                    width: "100%",
                    borderRadius: "5px",
                    padding: 0.8,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "100%",
                }}>
                <label for="discord" style={{ fontSize: "14px" }}>
                  Discord
                </label>
                <InputBase
                  value={formik.values.discord}
                  name="discord"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    border: "1px solid #cacaca",
                    width: "100%",
                    borderRadius: "5px",
                    padding: 0.8,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "100%",
                }}>
                <label for="linkedin" style={{ fontSize: "14px" }}>
                  linkedin
                </label>
                <InputBase
                  value={formik.values.linkedin}
                  name="linkedin"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    border: "1px solid #cacaca",
                    width: "100%",
                    borderRadius: "5px",
                    padding: 0.8,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "100%",
                }}>
                <label for="twitter" style={{ fontSize: "14px" }}>
                  Twitter
                </label>
                <InputBase
                  value={formik.values.twitter}
                  name="twitter"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    border: "1px solid #cacaca",
                    width: "100%",
                    borderRadius: "5px",
                    padding: 0.8,
                  }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: "100% !important" }}>
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default EditForm;
