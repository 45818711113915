/** @format */

import React from "react";
import VideosCard from "../VideosCard";
import Video from "../videos";

const Videoslist = ({ data }) => {
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((data, i) => <Video key={i} {...data} />)}
    </>
  );
};

export default Videoslist;
