/** @format */

import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import PortfolioCard from "../portfolioCard";

import image1 from "../../assets/logo/light.png";

import { useSelector } from "react-redux";

function PicChart() {
  const { data } = useSelector((state) => state.artsSlicer.singleArt);
  return (
    <Box>
      <>
        <Grid
          container
          sx={{
            padding: "1.5rem 0",
            borderBottom: "1px solid lightGrey",
            alignItems: "center",
          }}>
          <Grid item xs={4}>
            <PortfolioCard
              title={data?.title}
              creatorName={data?.creatorName}
              image={data?.image}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h2">
              {data?.fixedprice}$
              <img
                style={{ width: 50, marginLeft: 3 }}
                src={image1}
                alt="lightpic"
              />
            </Typography>
          </Grid>
        </Grid>
      </>
    </Box>
  );
}

export default PicChart;
