import { Box } from "@mui/material";

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        paddingLeft: { lg: 5, md: 3, sm: 2, xs: 1 },
        paddingRight: { lg: 5, md: 3, sm: 2, xs: 1 },
      }}
    >
      {children}
    </Box>
  );
};

export default Wrapper;
