/** @format */

import * as React from "react";
import {
  Box,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomAvatar from "../avatar";
import badge from "../../assets/badges/1 place.svg";
import { useSelector } from "react-redux";
import { PROFILE_BASE_URL } from "../../http/config";
import { Link } from "react-router-dom";

export default function InvestorsTable({ name, setName }) {
  const { topInvestors } = useSelector(
    (state) => state.singleCreator.leaderBoard
  );
  const filteredInvestors = topInvestors.filter((investor) =>
    investor.username.toLowerCase().includes(name)
  );

  return (
    <Box>
      <TableContainer sx={{}}>
        <Table>
          <TableHead borderBottom="1px solid #8F979E">
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  backgroundColor: "white",
                  fontSize: "18px",
                  width: "180px",
                  color: "#8F979E",
                }}>
                Name
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  backgroundColor: "white",
                  fontSize: "18px",
                  width: "100px",
                  color: "#8F979E",
                }}>
                Rate
              </TableCell>

              <TableCell
                align="center"
                sx={{
                  backgroundColor: "white",
                  fontSize: "18px",
                  width: "40px",
                  color: "#8F979E",
                }}>
                Total Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInvestors.map(
              ({ username, img, rating, total_amount }) => (
                <React.Fragment>
                  <TableRow
                    hover
                    tabIndex={-1}
                    component={Link}
                    sx={{ textDecoration: "none" }}
                    to={`/investor/${username}`}>
                    <TableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        borderBottom: "none",
                      }}>
                      <CustomAvatar
                        src={PROFILE_BASE_URL + img}
                        width={82}
                        height={82}
                      />
                      <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        {username}
                      </Typography>
                      {/* <img src={badge} alt="Badge" /> */}
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Rating value={parseInt(rating)} readOnly />
                      </Box>
                    </TableCell>

                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      {total_amount.toFixed(3)}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
