/** @format */

import axios from "axios";

const baseURL = "https://api.lumiplace.io";
const ImageBaseUrl = "https://ipfs.io/ipfs/";

export const lumiShare = axios.create({ baseURL: baseURL });
export const ImageUrl = axios.create({ baseURL: ImageBaseUrl });

export const PROFILE_BASE_URL = `${baseURL}/public/images/`;
export const COVERPAGE_BASE_URL = `${baseURL}/public/images/nfts/`;
export const STORIES_BASE_URL = `${baseURL}/public/stories/`;
export const VIDEOS_BASE_URL = `${baseURL}/public/videoUploads/`;
export const ARTICLES_BASE_URL = `${baseURL}/public/article/`;
