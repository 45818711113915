/** @format */

import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import MainButton from "../../components/buttonTwo";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail as EmailVerification } from "../../redux/auth/auth.actions";
import { useNavigate, useParams } from "react-router-dom";
import Notifications from "../../components/Notifications";
import { useEffect } from "react";
import { resetVerifyState } from "../../redux/auth/auth.slicer";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isVerifyEmailLoading, isVerifyEmailSuccess, isVerifyEmailFailed } =
    useSelector((state) => state?.auth);
  const { token } = useParams();
  const handleSubmit = () => {
    dispatch(EmailVerification(token));
  };

  useEffect(() => {
    setTimeout(() => {
      if (isVerifyEmailFailed || isVerifyEmailSuccess) {
        dispatch(resetVerifyState());
      }
    }, 2000);
    if (isVerifyEmailSuccess) {
      navigate("/");
    }
  }, [dispatch, isVerifyEmailFailed, isVerifyEmailSuccess]);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      width="100%"
      padding="80px 0">
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          width: "40%",
          justifyItems: "center",
          flexDirection: "column",
          alignItems: "center",
          padding: "80px 0px",
          gap: "30px",
          textAlign: "center",
        }}>
        <Box>
          <Typography variant="h1">
            Click On Verify Button To Activate Your LUMISHARE Account
          </Typography>
        </Box>
        <Box>
          {isVerifyEmailLoading ? (
            <CircularProgress sx={{ color: "black" }} size={15} />
          ) : (
            <MainButton
              onClick={handleSubmit}
              sx={{
                fontSize: "20px !important",
                "&:hover": {
                  backgroundColor: "#34C77B",
                },
              }}
              variant="contained">
              Verify
            </MainButton>
          )}
        </Box>
        <Notifications />
      </Paper>
    </Box>
  );
};
export default VerifyEmail;
