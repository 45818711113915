import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import Chart from "../../components/Chart/index";
const FeedTrafic = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        margin: "4rem 0",
      }}
    >
      <Typography variant="h1">Feed-in Tariff</Typography>
      {isSmallScreen ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="body1" sx={{ color: "#9DA4AA" }}>
              Daily Outputs:
            </Typography>
            <Typography variant="h2">54.09kWh</Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: "#9DA4AA" }}>
              Monthly Outputs:
            </Typography>
            <Typography variant="h2">54.09kWh</Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: "#9DA4AA" }}>
              Total Outputs:
            </Typography>
            <Typography variant="h2">54.09kWh</Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: "#9DA4AA" }}>
              Co, avoidance:
            </Typography>
            <Typography variant="h2">54.09kWh</Typography>
          </Box>
        </Box>
      ) : null}
      <Chart />
      <Box
        width="fit-content"
        display="flex"
        gap="5px"
        flexWrap="wrap"
        alignSelf="center"
        justifyContent="space-between"
      >
        <Box display="flex" gap="5px" alignItems="center">
          <Box
            width="15px"
            height="15px"
            sx={{ backgroundColor: "#FEDB33" }}
          ></Box>
          <Typography>PV System 93 kWh</Typography>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Box
            width="15px"
            height="15px"
            sx={{ backgroundColor: "#FDC10E" }}
          ></Box>
          <Typography>PV System 93 kWh</Typography>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Box
            width="15px"
            height="15px"
            sx={{ backgroundColor: "#E99511" }}
          ></Box>
          <Typography>PV System 93 kWh</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default FeedTrafic;
