import { Box, Typography } from "@mui/material";
const TradeCard = ({ img, title, description }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "5px",
        textAlign: "center",
      }}
    >
      <img src={img} alt="name" />
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
};
export default TradeCard;
