/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { artsData, buyNFT, getSingleArts, sellNFT } from "./arts.action";

const initialState = {
  isArtsDataLoading: false,
  isArtsDataLoadingError: false,
  isArtsDataLoadingSuccess: false,
  arts: {},
  isBuyArtsLoading: false,
  isBuyArtsLoadingFailed: false,
  isBuyArtsLoadingSuccess: false,
  buyArts: {},
  isBuyNFTloading: false,
  isBuyNFTloadingError: false,
  buyNFT: {},
  isGetSingleArtsLoading: false,
  isGetSingleArtsLoadingError: false,
  isGetSingleArtsSuccess: false,
  singleArt: {},
};

const artsSlicer = createSlice({
  name: "arts",
  initialState: initialState,
  reducers: {
    resetStateBuy: (state) => {
      state.isBuyArtsLoadingSuccess = false;
      state.isBuyArtsLoadingFailed = false;
      state.isBuyArtsLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(artsData.pending, (state) => {
      state.isArtsDataLoading = true;
    });
    builder.addCase(artsData.fulfilled, (state, action) => {
      state.isArtsDataLoading = false;
      state.arts = action.payload;
      state.isArtsDataLoadingSuccess = true;
    });
    builder.addCase(artsData.rejected, (state) => {
      state.isArtsDataLoading = false;
      state.isArtsDataLoadingError = true;
      state.isArtsDataLoadingSuccess = false;
    });
    //////////////////////////////////
    builder.addCase(sellNFT.pending, (state) => {
      state.isBuyNFTloading = true;
    });
    builder.addCase(sellNFT.fulfilled, (state, action) => {
      state.isBuyNFTloading = false;
      state.buyNFT = action.payload;
    });
    builder.addCase(sellNFT.rejected, (state) => {
      state.isBuyNFTloading = false;
      state.isBuyNFTloadingError = true;
    });
    ///////////////////////////////////////
    builder.addCase(buyNFT.pending, (state) => {
      state.isBuyArtsLoading = true;
    });

    builder.addCase(buyNFT.fulfilled, (state, action) => {
      state.isBuyArtsLoading = false;
      state.buyArts = action.payload;
      state.isBuyArtsLoadingSuccess = true;
    });
    builder.addCase(buyNFT.rejected, (state) => {
      state.isBuyArtsLoading = false;
      state.isBuyArtsLoadingFailed = true;
      state.isBuyArtsLoadingSuccess = false;
    });
    ////////////////////////////////////////////////////////////////
    builder.addCase(getSingleArts.pending, (state) => {
      state.isGetSingleArtsLoading = true;
    });

    builder.addCase(getSingleArts.fulfilled, (state, action) => {
      state.isGetSingleArtsLoading = false;
      state.singleArt = action.payload;
      state.isGetSingleArtsSuccess = true;
    });
    builder.addCase(getSingleArts.rejected, (state) => {
      state.isGetSingleArtsLoading = false;
      state.isGetSingleArtsLoadingError = false;
      state.isGetSingleArtsSuccess = false;
    });
  },
});

export const { resetStateBuy } = artsSlicer.actions;

export default artsSlicer.reducer;
