/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { getChatMessages, getChatUsers } from "./chats.action";

const initialState = {
  ischatUsersLoading: false,
  ischatUsersLoadingError: false,
  ischatUsersLoadingSuccess: false,
  chatUsers: [],
  ischatInfoLoading: false,
  ischatInfoLoadingFailed: false,
  ischatInfoLoadingSuccess: false,
  chatInfo: {},
  chatMessages: [],
};
const chatsSlicer = createSlice({
  name: "chats",
  initialState: initialState,
  reducers: {
    resetStateChat: (state) => {
      state.ischatInfoLoading = false;
      state.ischatInfoLoadingFailed = false;
      state.ischatInfoLoadingSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatUsers.pending, (state) => {
      state.ischatUsersLoading = true;
      state.ischatUsersLoadingError = false;
      state.ischatUsersLoadingSuccess = false;
    });
    builder.addCase(getChatUsers.fulfilled, (state, action) => {
      state.ischatUsersLoading = false;
      state.ischatUsersLoadingError = false;
      state.ischatUsersLoadingSuccess = true;
      state.chatUsers = action.payload;
    });
    builder.addCase(getChatUsers.rejected, (state) => {
      state.ischatUsersLoading = false;
      state.ischatUsersLoadingError = true;
      state.ischatUsersLoadingSuccess = false;
    });
    builder.addCase(getChatMessages.pending, (state) => {
      state.ischatInfoLoading = true;
      state.ischatInfoLoadingFailed = false;
      state.ischatInfoLoadingSuccess = false;
    });
    builder.addCase(getChatMessages.fulfilled, (state, action) => {
      state.ischatInfoLoading = false;
      state.ischatInfoLoadingFailed = false;
      state.ischatInfoLoadingSuccess = true;
      state.chatInfo = action.payload.info;
      state.chatMessages = action.payload.messages;
    });
    builder.addCase(getChatMessages.rejected, (state) => {
      state.ischatInfoLoading = false;
      state.ischatInfoLoadingFailed = true;
      state.ischatInfoLoadingSuccess = false;
      state.chatMessages = [];
    });
  },
});

export const { resetStateChat } = chatsSlicer.actions;

export default chatsSlicer.reducer;
