/** @format */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { storiesApi, storiesPostApi } from "./api";

export const storiesData = createAsyncThunk(
  "getStories/stories",
  async (payload) => {
    try {
      const response = await storiesApi(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const storiesPostData = createAsyncThunk(
  "postStories/stories",
  async (payload) => {
    try {
      const response = await storiesPostApi(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
