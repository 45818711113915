/** @format */

import { Box, Typography } from "@mui/material";
import bg from "../../assets/bgg.png";
import Button from "../button/index";
import MainButton from "../buttonTwo";
import { Link } from "react-router-dom";
const Trander = () => {
  return (
    <Box
      width="100%"
      height="350px"
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
      }}>
      <Box>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Typography
            sx={{
              fontSize: {
                lg: "80px",
                md: "60px",
                sm: "40px",
              },
              fontWeight: "bold",
              color: "#34C77B",
            }}>
            Copy
          </Typography>
          <Typography
            sx={{
              fontSize: {
                lg: "80px",
                md: "60px",
              },
              fontWeight: "lighter",
              color: "white",
            }}>
            TRADER
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography
            variant="h1"
            color="white"
            sx={{ fontSize: "30px", textAlign: "start" }}>
            Invest like Lumiplace top investors
          </Typography>
          <Typography sx={{ fontSize: "20px", color: "white" }}>
            Replicate the investment moves of other traders in real time,
            automatically.
          </Typography>

          <MainButton variant="contained" component={Link} to="/copytrader">
            More Details
          </MainButton>
        </Box>
      </Box>
    </Box>
  );
};
export default Trander;
