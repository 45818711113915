/** @format */

import ImageSlider from "../../components/farmProfileSlider";
import ProfileDetails from "../../components/farmProfileDetails";
import EnergyProduce from "../../components/EnergyProduce";
import { Box, Typography } from "@mui/material";
import Wrapper from "../../components/wrapper";
import FeedTrafic from "../../components/feedTrafic";
import FarmVideo from "../../components/farmProfileVideo";
import { useDispatch, useSelector } from "react-redux";
import { getSingleArts } from "../../redux/arts/arts.action";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loading from "../../components/loading";
import GoogleMap from "../../components/searchlocation";

const FarmProfile = () => {
  const dispatch = useDispatch();
  const { tokenID, amountId } = useParams();
  useEffect(() => {
    let payload = {
      tokenID,
      amountId,
    };
    dispatch(getSingleArts(payload));
  }, [dispatch, tokenID, amountId]);
  const { isGetSingleArtsSuccess } = useSelector((state) => state.artsSlicer);
  return (
    <>
      {!isGetSingleArtsSuccess ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <ProfileDetails />
          <ImageSlider />
          <Box sx={{ margin: "4rem 0" }}>
            <EnergyProduce title="Electricity Produced" />
          </Box>
          <Wrapper>
            <FeedTrafic />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                margin: "0 0 4rem 0",
              }}>
              <Typography variant="h1"> Farm Live 24/7</Typography>
              <FarmVideo />
            </Box>
            <Box
              margin="0px 0px 4rem 0px"
              sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography variant="h1">Location</Typography>
              <Box width="100%" height="300px" borderRadius="10px">
                <GoogleMap
                  apiKey="uixftprdymzdgtqk"
                  zoom={10}
                  center={{ lat: 37.7749, lng: -122.4194 }} // Example center: San Francisco
                />
              </Box>
            </Box>
          </Wrapper>
        </>
      )}
    </>
  );
};
export default FarmProfile;
