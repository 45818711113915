/** @format */

import { Box, Card, IconButton, Paper, Rating } from "@mui/material";
import { Typography } from "@mui/material";
import { BsShareFill } from "react-icons/bs";
import { BiSun } from "react-icons/bi";
import Button from "../button";
import CustomAvatar from "../avatar";
import { useState } from "react";
import { WiLightning } from "react-icons/wi";
import { AiOutlineBulb } from "react-icons/ai";
import Ligghting from "../../assets/lightning.svg";
import Cancel from "../../assets/Red cancel.svg";
import { addToWishList } from "../../redux/wishlist/wishlist.action";
import { useDispatch, useSelector } from "react-redux";
import { ARTICLES_BASE_URL } from "../../http/config";
import { Link } from "react-router-dom";
const ArticlesCard = ({
  id,
  backgroundImage,
  avatarImage,
  title,
  subTitle,
  articleId,
  description,
  image,
  creator,
  farmOwnerName,
  date,
  detail,
  articles,
  onMouseEnter,
  onMouseLeave,
  index,
  onNextClick,
  singleCardView,
  handlePopoverOpen,
  handlePopoverClose,
}) => {
  const [isFirstButtonHovered, setIsFirstButtonHovered] = useState(false);
  const handleFirstButtonHover = () => {
    setIsFirstButtonHovered(true);
  };
  const handleFirstButtonLeave = () => {
    setIsFirstButtonHovered(false);
  };
  const dispatch = useDispatch();

  const handleSubmit = () => {
    onNextClick(index + 1);
  };

  return (
    <Box display="flex" justifyContent="center" borderRadius="10px" gap="20px">
      {" "}
      {/* Centering the card */}
      <Card
        sx={{
          borderRadius: "10px",
          // marginRight: "10px",
          width: "360px",
          position: "relative",
          overflow: "visible !important",

          // Ensure the card is positioned relatively
        }}>
        {/* IconButton at the left side */}
        {singleCardView && (
          <Paper
            elevation={5}
            style={{
              position: "absolute",
              left: "-10%",
              top: "50%",
              transform: "translateY(-50%)",
              borderRadius: "50px",
              padding: 1,
              zIndex: 1000,
              // overflow: "visible !important",
            }}>
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
              onClick={() => onNextClick(index - 1)}>
              <img src={ARTICLES_BASE_URL + image} alt="" />
            </IconButton>
          </Paper>
        )}
        <Box
          sx={{
            width: "100%",
            height: "150px",
            position: "relative",
          }}>
          {/* Image and share icon button */}
          <img
            src={ARTICLES_BASE_URL + image}
            alt="name"
            style={{
              width: "100%",
              objectFit: "cover",
              height: "100%",
              borderRadius: "10px 10px 0 0 ",
            }}
          />
          <IconButton
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              fontSize: "12px",
              backgroundColor: "#E0E5E7",
              "&:hover": {
                backgroundColor: "#FFFFFF",
              },
            }}>
            <BsShareFill />
          </IconButton>
          {/* Centered content */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              position: "absolute",
              bottom: "0px",
              padding: "0px 15px",
              boxSizing: "border-box",
            }}>
            <Box>
              <Typography variant="h3" sx={{ color: "white" }}>
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* Bottom content */}
        <Box
          sx={{
            padding: "0px 5px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}>
          {/* Content with two sections */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}>
              <Box>
                <CustomAvatar
                  src={avatarImage}
                  alt="dfsdfsd"
                  width="28px"
                  height="28px"
                />
              </Box>
              <Box>
                <Typography variant="h3" component="h2">
                  {creator}
                </Typography>
                <Typography variant="body1" component="p">
                  {date}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: "0px 5px" }}>
            <Typography variant="h3">Article</Typography>
            <Typography>{description}</Typography>
          </Box>
        </Box>

        {/* {isFirstButtonHovered ? (
          <Box
            sx={{
              height: "43px !important",
              borderRadius: "0px 0px 10px 10px",
              transition: "background 0.6s ease-in",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: 4,
              paddingLeft: 3,
              color: "#fff",
              backgroundColor: "#34C77B !important",
            }}
            onMouseLeave={() => handleFirstButtonLeave()}>
            <BiSun size={"1rem"} />
              <WiLightning size={"1.5rem"} />
              <AiOutlineBulb size={"1rem"} />
          </Box>
        ) : ( */}
        <Box
          sx={{
            display: "grid",
            // boxShadow: "-7px 18px 46px 3px gray",
            gridTemplateColumns: "0.5fr 3.5fr",
          }}>
          <Button variant="outlined" onMouseEnter={handleFirstButtonHover}>
            <BiSun />
          </Button>

          <Link to={`/article/${articleId}`}>
            {" "}
            <Button variant="outlined">Read More </Button>
          </Link>
        </Box>

        {/* IconButton at the right side */}
        {singleCardView && (
          <Paper
            elevation={5}
            style={{
              position: "absolute",
              right: -30,
              top: "50%",
              transform: "translateY(-50%)",
              borderRadius: "50px",
              padding: 1.3,
              zIndex: 1000,
            }}>
            <IconButton
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}>
              <img src={Ligghting} width={15} alt="" />
            </IconButton>
          </Paper>
        )}
      </Card>
    </Box>
  );
};
export default ArticlesCard;
