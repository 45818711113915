/** @format */

import { Box, Typography } from "@mui/material";
import "./style.css";
import { data } from "./data";
import ArticlesCard from "../articlesCard";
import Carousel from "react-elastic-carousel";
import { CarouselArrow } from "../../utiles/units";
import MouseOverPopover from "../popover";
import { useState } from "react";
import { useSelector } from "react-redux";

const Articles = ({ singleCardView }) => {
  const { articles, isArticlesLoadingSuccess } = useSelector(
    (state) => state.articles
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleNextClick = () => {
    const nextIndex = articles === 0 ? 0 : (currentIndex + 1) % articles;

    setCurrentIndex(nextIndex);
  };
  if (singleCardView) {
    var breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 1 },
      { width: 768, itemsToShow: 1 },
      { width: 1200, itemsToShow: 1 },
    ];
  } else {
    var breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 768, itemsToShow: 4 },
      { width: 1200, itemsToShow: 4 },
    ];
  }
  const renderArticles = () => {
    return (
      <div
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          display: "flex",
          padding: "20px",
          marginY: "20px",
        }}>
        <Carousel
          autoPlaySpeed={5000}
          enableAutoPlay
          disableArrowsOnEnd={false}
          breakPoints={breakPoints}
          infinite={true}
          pagination={false}
          renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}>
          {articles.map((data, i) => (
            <ArticlesCard key={i} {...data} />
          ))}
        </Carousel>
      </div>
    );
  };

  return (
    <Box>
      <Box>
        <Typography sx={{ paddingLeft: "40px" }} variant="h1">
          Articles
        </Typography>
      </Box>
      <Box>{renderArticles()}</Box>
    </Box>
  );
};

export default Articles;
