/** @format */
import { useSelector } from "react-redux";
import { Typography, Rating, IconButton, Box } from "@mui/material";
import { AiOutlineInstagram } from "react-icons/ai";
import {
  BiLogoDiscordAlt,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiSolidShareAlt,
} from "react-icons/bi";
import { Link } from "react-router-dom";
import badge from "../../assets/place.png";
const UserDetails = () => {
  const { data } = useSelector((state) => state.singleCreator.creator);
  const socialIcons = [
    { icon: <AiOutlineInstagram fontSize="medium" />, label: "Instagram" },
    { icon: <BiLogoDiscordAlt fontSize="medium" />, label: "Discord" },
    { icon: <BiLogoLinkedin fontSize="medium" />, label: "LinkedIn" },
    { icon: <BiLogoTwitter fontSize="medium" />, label: "Twitter" },
    { icon: <BiSolidShareAlt fontSize="medium" />, label: "Share" },
  ];
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",

              width: {
                lg: "610px",
                md: "550px",
              },
            }}>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                width: "fit-content",
                margin: "10px 0px",
                flexWrap: "wrap",
              }}>
              <Typography variant="h1">{data?.username}</Typography>
              <img src={badge} alt="badge" width="20px" />
              <Rating value={data?.ratings} component="legend" />
              <Typography variant="body1" sx={{ color: "#8F979E" }}>
                ({data?.ratings})
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              width: "fit-content",
            }}>
            <IconButton
              component={Link}
              to={data?.instagram}
              sx={{
                background: "white",
                color: "black",
                "&:hover": {
                  background: "white",
                },
              }}
              aria-label={socialIcons[0].label}>
              {socialIcons[0].icon}
            </IconButton>
            <IconButton
              component={Link}
              to={data?.instagram}
              sx={{
                background: "white",
                color: "black",
                "&:hover": {
                  background: "white",
                },
              }}
              aria-label={socialIcons[1].label}>
              {socialIcons[1].icon}
            </IconButton>
            <IconButton
              component={Link}
              to={data?.instagram}
              sx={{
                background: "white",
                color: "black",
                "&:hover": {
                  background: "white",
                },
              }}
              aria-label={socialIcons[2].label}>
              {socialIcons[2].icon}
            </IconButton>
            <IconButton
              component={Link}
              to={data?.instagram}
              sx={{
                background: "white",
                color: "black",
                "&:hover": {
                  background: "white",
                },
              }}
              aria-label={socialIcons[3].label}>
              {socialIcons[3].icon}
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              lg: "50%",
              sm: "100%",
            },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}>
          <Box sx={{ display: "flex", gap: "20px", margin: "10px 0px" }}>
            <Box>
              <Typography variant="body1" sx={{ color: "#8F979E" }}>
                Followers
              </Typography>
              <Typography variant="h1">{data.followers}</Typography>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ color: "#8F979E" }}>
                Posts
              </Typography>
              <Typography variant="h1">{data.posts}</Typography>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ color: "#8F979E" }}>
                Following
              </Typography>
              <Typography variant="h1">{data.following}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "400px" }}>
          <Typography>
            this is for description this is for description this is for
            description this is for description this is for description this is
            for description this is for descriptionthis is for description this
            is for description
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default UserDetails;
